import React from 'react';
import AppProvider from './AppProvider';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Boot from './redux/boot';
import { Provider } from 'react-redux';
import PublicRoutes from './router';
import { store, history } from './redux/store';
import ScrollToTop from './helper/ScrollToTop';
import { GlobalStyle } from "./components/common/styles/global.js";
import HomeOne from './HomeOne';
import HomeTwo from './HomeTwo';
import About from './pages/about/About';
import CourseGrid from './pages/courses/CourseGrid';
import CourseList from './pages/courses/CourseList';
import CourseDetails from './pages/courses/CourseDetails';
import Instructor from './pages/instructor/Instructors';
import InstructorDetails from './pages/instructor/InstructorDetails';
import Gallery from './pages/gallery/Gallery';
import Events from './pages/events/Events';
import EventDetails from './pages/events/EventsDetails';
import Login from './pages/account/Login';
import Register from './pages/account/Register';
import Contact from './pages/contact/Contact';
import Faq from './pages/faq/Faq';
import PageNotFound from './pages/404/PageNotFound';
import ComingSoon from './pages/comingsoon/ComingSoon';
import BlogClassic from './pages/blog/BlogClassic';
import BlogGrid from './pages/blog/BlogGrid';
import BlogDetails from './pages/blog/BlogDetails';
import Product from './pages/shop/Products';
import ProductDetails from './pages/shop/ProductDetails';
import Cart from './pages/shop/Cart';



function App() {
   
    return (
        <Provider store={store}>
            <AppProvider>
                <>
                    <GlobalStyles />
                    <PublicRoutes history={history} />
                </>
                </AppProvider>
        </Provider>
    )
}

export default App;