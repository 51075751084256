const DOCUMENT = 'ALUNO_';

const actions = {
  LOAD: DOCUMENT + 'LOAD',
  LOAD_SUCCESS: DOCUMENT + 'LOAD_SUCCESS',
  LOAD_ERROR: DOCUMENT + 'LOAD_ERROR',

  WAS_LOAD:DOCUMENT + 'WAS_LOAD',

  INSCREVER: DOCUMENT + 'INSCREVER',
  INSCREVER_SUCCESS: DOCUMENT + 'INSCREVER_SUCCESS',

  DOWNLOAD: DOCUMENT + 'DOWNLOAD',

  LOAD_ANEXOS: DOCUMENT + 'LOAD_ANEXOS',
  LOAD_ANEXOS_SUCCESS: DOCUMENT + 'LOAD_ANEXOS_SUCCESS',
  LOAD_ANEXOS_ERROR: DOCUMENT + 'LOAD_ANEXOS_ERROR',

  load: (idCurso,emailProfessor,emailAluno) => {
    return { type: actions.LOAD,
          payload: {idCurso, emailProfessor, emailAluno}};
  },

  inscrever: (idCurso,emailAluno) => {
    return { type: actions.INSCREVER,
          payload: {idCurso, emailAluno}};
  },

  loadSuccess: (data, inscrito) => ({
    type: actions.LOAD_SUCCESS,
    payload: { data, inscrito },
  }),

  loadError: error => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),

  loadAnexos: (email, key) => {
    return { type: actions.LOAD_ANEXOS,
          payload: {email, key}};
  },

  loadAnexosSuccess: data => ({
    type: actions.LOAD_ANEXOS_SUCCESS,
    payload: { data },
  }),

  loadAnexosError: error => ({
    type: actions.LOAD_ANEXOS_ERROR,
    payload: { error },
  }),

  downloadFile: (data, fileName) => ({
    type: actions.DOWNLOAD,
    payload: { 
      "caminho": data,
      "fileName":fileName 
    },
  }),
};
export default actions;
