import Usuario from "../../model/Usuario";

export const actions = {
  NO_LOGGED: 'NO_LOGGED',
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  EMAIL_VERIFICATION_SAGA: 'EMAIL_VERIFICATION_SAGA',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  EMAIL_RESET: 'EMAIL_RESET',
  EMAIL_RESET_OK: 'EMAIL_RESET_OK',
  EMAIL_RESET_ER: 'EMAIL_RESET_ER',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  GET_USER: 'GET_USER',
  CREATE_USER: 'CREATE_USER',
  CHANGE_LOGIN: 'CHANGE_LOGIN',
  CHANGE_LOGIN_SUCCESS: 'CHANGE_LOGIN_SUCCESS',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',
  CHECK: 'CHECK',
  ROUTE_TO_REDIRECT: 'ROUTE_TO_REDIRECT',
  checkAuthorization: (page='') => ({ type: actions.CHECK_AUTHORIZATION , payload:{page}}),
  login: (payload = false) => ({
    type: actions.LOGIN_REQUEST,
    payload: payload
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  getUser:(user) => ({
    type: actions.GET_USER,
    user: user 
  }),
  changeLogin: (email) => ({
    type: actions.CHANGE_LOGIN,
    email:email,
  }),
  routeToRedirect: (route) => ({
    type: actions.ROUTE_TO_REDIRECT,
    route,
  }),
  emailReset: (email) => ({
    type: actions.EMAIL_RESET,
    email:email
  })
};

export const emailVerificationSaga = (payload) => ({
  type: actions.EMAIL_VERIFICATION_SAGA,
  payload:payload
});

export const emailReset = (email) => ({
  type: actions.EMAIL_RESET,
  email:email
});

export const createUser = (email,password,name) => ({
  type: actions.CREATE_USER,
  email:email,
  password:password,
  name:name,
});

export const getUser = () => {
  return dispatch => {
      dispatch(Usuario.getUser())
  }
}


export default actions;
