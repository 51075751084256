import React, { Component } from 'react';
// import Datas from '../data/team/team-slider.json';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { Styles } from "./styles/teamSlider.js";
import { connect } from 'react-redux';


class TeamSlider extends Component {
   


    render() {
        const settings = {
            slidesPerView: 4,
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            spaceBetween: 30,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-dot.text-center',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                992: {
                    slidesPerView: 4
                }
            }
        };

        const re = new RegExp("^((http(s?):\/\/))");

        const showIcon=(icons,type)=>{

            if(type=='facebook'){
                if(re.test(icons.facebook)) {
                    return(<li className="list-inline-item"><a target="_BLANK" href={process.env.PUBLIC_URL + icons.facebook}><i className="fab fa-facebook-f"></i></a></li>)
                }
            }
           
            if(type=='linkedin'){
                if(re.test(icons.linkedin)){
                    return(<li className="list-inline-item"><a target="_BLANK" href={process.env.PUBLIC_URL + icons.linkedin}><i className="fab fa-linkedin"></i></a></li>)
                }
            }
            
            if(type=='instagram'){
                if(re.test(icons.instagram)){
                    return(<li className="list-inline-item"><a target="_BLANK" href={process.env.PUBLIC_URL + icons.instagram}><i className="fab fa-instagram"></i></a></li>)
                }
            }

            if(type=='youtube'){
                if(re.test(icons.youtube)){
                    return(<li className="list-inline-item"><a target="_BLANK" href={process.env.PUBLIC_URL + icons.youtube}><i className="fab fa-youtube"></i></a></li>)
                }   
            }
        }

        const showInstrutor=(dataInst)=>{
            try{
                if(Object.keys(dataInst.instrutores).length >0){
                    let instrutor;
                    instrutor = Object.keys(dataInst.instrutores).map((kdata, index) => {
                        return (
                            <div className="team-item" key={kdata}>
                                <img height="825" width="640" src={dataInst.instrutores[kdata]['urlFoto']} alt="" className="img-fluid" />
                                <div className="img-content text-center">
                                    <h5>{dataInst.instrutores[kdata]['nome']}</h5>
                                    <p>Instrutor</p>
                                    <ul className="list-unstyled list-inline">
                                        {showIcon(dataInst.instrutores[kdata],'facebook')}
                                        {showIcon(dataInst.instrutores[kdata],'instagram')}
                                        {showIcon(dataInst.instrutores[kdata],'youtube')}
                                        {showIcon(dataInst.instrutores[kdata],'linkedin')}
                                    </ul>
                                </div>
                            </div>
                        )
                      })
                      return instrutor;
                }
            }catch(error){
            }
            
        }
        

        return (
            <Styles>
                {/* Team Slider */}
                <section className="team-member-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>Temos um fantástico time de professores</h4>
                                </div>
                            </Col>
                            <Col md="12" className="team-slider">
                                <Swiper {...settings}>
                                    {showInstrutor(this.props.Data)}
                                </Swiper>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}export default connect(
    state => ({
        Data: state.PageHome,
    })  
  )(TeamSlider);

// export default TeamSlider
