import React, { useEffect} from 'react';
import { Link,Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/account.js';
import {createUser} from '../../redux/auth/actions';
import {useSelector, useDispatch} from 'react-redux';


//https://levelup.gitconnected.com/react-redux-hooks-useselector-and-usedispatch-f7d8c7f75cdd
function Register() {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.Auth.isLoggedIn)
    const createUserSuccess = useSelector(state => state.Auth.createUser)
    const createUserError = useSelector(state => state.Auth.createUserError)
    const msg = useSelector(state => state.Auth.msg)

    useEffect(() => {
        const form = document.getElementById("form_registration");
        const fname = document.getElementById("registration_fname");
        const lname = document.getElementById("registration_lname");
        const email = document.getElementById("registration_email");
        const password = document.getElementById("registration_password");
        const cpassword = document.getElementById("registration_cpassword");
        
        try{
            form.addEventListener("submit", formSubmit);
        }catch(error){
            console.log(error)
        }
        

        function formSubmit(e) {
            e.preventDefault();
    
            const fnameValue = fname.value.trim();
            const lnameValue = lname.value.trim();
            const emailValue = email.value.trim();
            const passwordValue = password.value.trim();
            const cpasswordValue = cpassword.value.trim();
            let ok = true;
    
            if (fnameValue === "") {
                setError(fname, "Nome deve ser preenchido");
                ok=false;
            } else {
                setSuccess(fname);
            }
    
            if (lnameValue === "") {
                setError(lname, "Sobrenome deve ser preenchido");
                ok=false;
            } else {
                setSuccess(lname);
            }
    
            if (emailValue === "") {
                setError(email, "Preencher E-mail");
                ok=false;
            } else if (!isEmail(emailValue)) {
                setError(email, "E-mail Inválido");
                ok=false;
            } else {
                setSuccess(email);
            }
    
    
            if (passwordValue === "") {
                ok=false;
                setError(password, "Senha deve ser preenchida");
            } else if(passwordValue.length < 6 ){
                ok=false;
                setError(password, "Senha deve ter mais que 6 caracteres");
            } else {
                setSuccess(password);
            }
    
            if (cpasswordValue === "" || passwordValue !== cpasswordValue) {
                ok=false;
                setError(cpassword, "Senha não confere");
            } else {
                setSuccess(cpassword);
            }
    
            if(ok){
                dispatch(createUser(emailValue,passwordValue,fnameValue+" "+lnameValue));                
            }
        }
        function setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".registration_input-msg");
            formControl.className = "form-control text-left error";
            errorMsg.innerText = message;
    
        }
    
        function setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }
    
        function isEmail(email) {
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        }
    },[]);

        const from = { pathname: 'dashboard' };
        if (isLoggedIn) {
            return <Redirect to={from} />;
        }
    return (
        

        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper registration-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Registro" />

                {/* Registration Area */}
                <section className="registration-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="registration-box">
                                    <div className="registration-title text-center">
                                        <h3>Registro</h3>
                                    </div>
                                    <form id="form_registration" className="form">
                                        <p className="form-control">
                                            <label htmlFor="registration_fname">Nome</label>
                                            <input type="text" placeholder="Nome" id="registration_fname" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="registration_lname">Sobrenome</label>
                                            <input type="text" placeholder="Sobrenome" id="registration_lname" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="registration_email">E-mail</label>
                                            <input type="email" placeholder="Email address" id="registration_email" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                       
                                        <p className="form-control">
                                            <label htmlFor="registration_password">Senha</label>
                                            <input type="password" placeholder="*******" id="registration_password" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="registration_cpassword">Confirmar Senha</label>
                                            <input type="password" placeholder="********" id="registration_cpassword" />
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <button>Registrar Agora</button>
                                    </form>
                                    <div className="have_account-btn text-center">
                                        <p>Já tem uma conta? <Link to="/login">Login</Link></p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        </Styles>
    )
}
export default Register