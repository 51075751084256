import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  status:'init',
  linkPagamento:'',
  idPagamento:false,
  buttonDisabled:false,
  step:{
    0:false,
    1:false,
    2:false,
    3:false,
  },
  
};

export default function reducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.STEP_VALID:
      return {
        ...state,
        step: {...state.step, [payload.step]:payload.valid}
      };
    case actions.CONCLUIR_PEDIDO_SUCCESS:
      return {
        ...state,
        idPagamento:payload.id,
        linkPagamento:payload.shortUrl,
        step: {...state.step, [1]:true}
      };  
    case actions.CONCLUIR_PEDIDO:
      return {
        ...state,
        buttonDisabled:true,
        status:'init'
      }; 
    case actions.CONCLUIR_PEDIDO_ERROR:
      return {
        ...state,
        buttonDisabled:false
      }; 
      
    case actions.CONSULTA_PAGAMENTOS:
      return {
        ...state,
        status:'consultando'
      }; 

    case actions.CONSULTA_PAGAMENTOS_SUCCESS:
      return {
        ...state,
        status:'consultado'
      };   

    case actions.CONSULTA_PAGAMENTOS_ERROR:
      return {
        ...state,
        status:'init'
      };      

    default:
      return state;
  }
}
