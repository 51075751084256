import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import omit from 'lodash/omit';
import { rsf} from '@iso/lib/firebase/firebase';
import notifications from '@iso/components/Feedback/Notification';
import {getStatistic, getPerguntas} from '@iso/api/perguntar'
import obj from 'nfjjs/obj';


function* loadPerguntas({ payload }) {
  try {
    let dt = yield call(getPerguntas,payload.emailProfessor,payload.emailAluno, payload.idCurso, payload.pessoa, payload.respondida);
    let perguntas=[];
    let data='';
    if(Object.keys(dt).length>0){
      Object.keys(dt).map(k=>{
        if(dt[k]['data']){
          data = dt[k]['data'].toDate().getTime();
        }

        perguntas.push({
          id:obj.getValue(dt[k],'id'),
          pergunta:obj.getValue(dt[k],'pergunta'),
          emailAluno:obj.getValue(dt[k],'emailAluno'),
          emailProfessor:obj.getValue(dt[k],'emailProfessor'),
          assunto:obj.getValue(dt[k],'assunto'),
          respostas:obj.getValue(dt[k],'respostas'),
          nomeCurso:obj.getValue(dt[k],'nomeCurso'),
          cursoId:obj.getValue(dt[k],'cursoId'),
          idPergunta:obj.getValue(dt[k],'idPergunta'),
          respondido:obj.getValue(dt[k],'respondido'),
          data: data
        });
      })
    
    }
    yield put({ type: actions.LOAD_PERGUNTAS_SUCCESS, payload:{perguntas:perguntas}});
  }catch(err){
    console.log(err);
    yield put({ type: actions.LOAD_PERGUNTAS_ERROR});
  }
}

function* loadEstatistica({ payload }) {
  let total = 0;
  let totalResp = 0;
  let dt = yield call(getStatistic,payload.emailProfessor,payload.emailAluno, payload.idCurso, payload.pessoa, payload.respondida);
  let dash = [];
  try{
      if(payload.pessoa=='instrutor'){
        if(Object.keys(dt).length>0){
          if(dt.hasOwnProperty('professores')){
              if(dt.professores.hasOwnProperty(payload.emailProfessor)){
                  total = dt.professores[payload.emailProfessor];
              }
          }
          if(dt.hasOwnProperty('respostas_professores')){
              if(dt.respostas_professores.hasOwnProperty(payload.emailProfessor)){
                  totalResp = dt.respostas_professores[payload.emailProfessor];
              }
          }
          
  
          if(dt.hasOwnProperty('professores_resp')){
              if(dt.professores.hasOwnProperty(payload.emailProfessor)){
                  total = dt.professores[payload.emailProfessor];
              }
              
          }
          dash.push({text:'Total Perguntas',title: total});
          dash.push({text:'Total Respondidas',title: totalResp});
          dash.push({text:'Total A Responder',title: total-totalResp});

      }else if(payload.pessoa=='gestor'){
        if(dt.hasOwnProperty('professores')){
          if(dt.professores.hasOwnProperty(payload.emailProfessor)){
              total = dt.professores[payload.emailProfessor];
          }
      }
      if(dt.hasOwnProperty('respostas_professores')){
          if(dt.respostas_professores.hasOwnProperty(payload.emailProfessor)){
              totalResp = dt.respostas_professores[payload.emailProfessor];
          }
      }
      dash.push({text:'Total Perguntas',title: dt.professores[payload.email]});

      if(dt.hasOwnProperty('professores_resp')){
          if(dt.professores.hasOwnProperty(payload.emailProfessor)){
              total = dt.professores[payload.emailProfessor];
          }
          
      }
      dash.push({text:'Total Respondidas',title: totalResp});
      dash.push({text:'Total A Responder',title: total-totalResp});
      }
      
      yield put({ type: actions.LOAD_ESTATISTICA_SUCCESS, payload:{dash}});
    }
  }catch(err){
    console.log(err);
    yield put({ type: actions.LOAD_ESTATISTICA_ERROR, payload:{dash}});
  }
    
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_PERGUNTAS, loadPerguntas),
    takeEvery(actions.LOAD_ESTATISTICA, loadEstatistica),
  ]);
}
