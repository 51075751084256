import { all, takeEvery, put, call} from 'redux-saga/effects';
import { eventChannel } from "redux-saga";
import actions from './actions';
import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';

const COLLECTION_NAME = 'cad_instrutor_perfil';


function* loadFromFirestore({email}) {
  try {    
    const snapshot = yield call(rsf.firestore.getDocument, COLLECTION_NAME+"/"+email);
    let data;
    if(snapshot){
      data=snapshot.data();
      if(!data){
        data=[];
      }
    }
    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error)
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* storeIntoFirestore({ payload, email }) {
  const { data } = payload;
  const fdata = {...omit(data, ['file'])}
  try {
    yield call(rsf.firestore.setDocument, COLLECTION_NAME+"/"+email, fdata,{merge:true});
        
    if(data.file){
      yield call(uploadFile,'public/instrutor/'+email+'/perfil',data.file,email)
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE, email });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoFireStoreError(error));
  }
}

function* uploadFile(path, file,email) {
  const task = rsf.storage.uploadFile(path, file);
  const channel = eventChannel(emit => task.on('state_changed', emit));
  yield task
  yield call(updatePathUpload,path,email)
}

function* updatePathUpload(path,email){
  console.log(`${COLLECTION_NAME}/${email}`,email,'sagasInstrutorPerfil',path)
  let url = yield call(getUrl,path)
  yield call(
    rsf.firestore.setDocument,
    `${COLLECTION_NAME}/${email}`,
    {
      "caminho":path,
      "urlFoto":url.replace(/&token(.*)/, '')
    },{merge: true}
  );
}

function* getUrl(caminho) {
  try{
    const url = yield call(rsf.storage.getDownloadURL, caminho);
    return url;
  }catch(erro){
    console.log(erro)
    return '';
  }
  
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
  ]);
}
