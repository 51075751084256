import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import Search from './common/Search';
import Sidebar from './common/Sidebar';
import StickyMenu from './common/StickyMenu';
import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/header.js";
import settings, {siteConfig} from '../settings';


class Header extends Component {
    render() {
        return (
            <Styles>
                {/* Topbar */}
                <section className="top-bar">
                    <Container>
                        <Row>
                            <Col lg="6" md="5">
                                <div className="bar-left">
                                    <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item"><i className="las la-map-marker"></i>{siteConfig.addressFull}</li>
                                        <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/contato"}>Alguma dúvida?</Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg="6" md="7">
                                <div className="bar-right d-flex justify-content-end">
                                    <ul className="list-unstyled list-inline bar-social">
                                    <li className="list-inline-item"><a href={siteConfig.facebook}><i className="fab fa-facebook-f"></i></a></li>
                                    {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li> */}
                                    <li className="list-inline-item"><a href={siteConfig.linkedin}><i className="fab fa-linkedin-in"></i></a></li>
                                    <li className="list-inline-item"><a href={siteConfig.instagram}><i className="fab fa-instagram"></i></a></li>
                                    {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li> */}
                                    </ul>
                                    <ul className="list-unstyled list-inline bar-lang">
                                        <li className="list-inline-item">
                                            <Dropdown>
                                                <Dropdown.Toggle as="a"><img src={process.env.PUBLIC_URL + "/assets/images/bra.png"} alt="" />Brasil<i className="las la-angle-down"></i></Dropdown.Toggle>
                                                <Dropdown.Menu as="ul">
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/bra.png"} alt="" /> Brasil</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled list-inline bar-login">
                                        <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/login"}><i className="las la-user"></i>Login</Link></li>
                                        {/* <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/registration"}><i className="las la-user-edit"></i>Registrar</Link></li> */}
                                        <li className="list-inline-item"><a href="https://lp.coopercont.com.br/cqc/"><i className="las la-user-edit"></i>Registrar</a></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Logo Area */}
                <section className="logo-area">
                    <Container>
                        <Row>
                            <Col md="3">
                                <div className="logo">
                                    <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/logo_171_41_blank.png"} alt="" /></Link>
                                </div>
                            </Col>
                            <Col md="9">
                                <div className="logo-contact-box d-flex justify-content-end">
                                    <div className="emcontact-box d-flex">
                                        <div className="box-icon">
                                            <i className="flaticon-phone-call"></i>
                                        </div>
                                        <div className="box-content">
                                            <p>Entre em contato</p>
                                            <span>{siteConfig.fone}</span>
                                        </div>
                                    </div>
                                    <div className="emcontact-box d-flex">
                                        <div className="box-icon">
                                            <i className="flaticon-envelope"></i>
                                        </div>
                                        <div className="box-content">
                                            {/* <p>Enquery Us</p> */}
                                            <span>contato@coopercont.com.br</span>
                                        </div>
                                    </div>
                                    <div className="apply-btn">
                                        <Link to={process.env.PUBLIC_URL + "/login"}><i className="las la-clipboard-list"></i>Entrar</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Navbar */}
                <section className="main-menu">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="main-menu-box">
                                    <div className="menu-box d-flex justify-content-between">
                                        <ul className="nav menu-nav">
                                            <li className="nav-item dropdown active">
                                                <Link className="nav-link" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Home <i className="las"></i></Link>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/cursos"} data-toggle="dropdown">Cursos </Link>
                                                {/* <ul className="dropdown list-unstyled">
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-grid"}>Ao Vivo</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-list"}>Gravados</Link></li>
                                                </ul> */}
                                            </li>
                                            <li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/instrutores"} data-toggle="dropdown">Instrutores</Link>
                                            </li>
                                            {/* <li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/eventos"} data-toggle="dropdown">Eventos <i className="las"></i></Link>
                                            </li> */}
                                            <li className="nav-item dropdown">
                                                {/* <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/planos"} data-toggle="dropdown">Planos</Link> */}
                                                <a className="nav-link dropdown-toggle"  href="https://lp.coopercont.com.br/cqc/">Planos</a>
                                            </li>
                                            <li className="nav-item dropdown">
                                                {/* <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/contratar"} data-toggle="dropdown">Contratar</Link> */}
                                                <a className="nav-link dropdown-toggle" href="https://lp.coopercont.com.br/cqc/">Contratar</a>
                                            </li>
                                        </ul>
                                        <ul className="nav search-cart-bar">
                                            {/* <li className="nav-item search-box">
                                                <Search />
                                            </li> */}
                                            {/* <li className="nav-item cart-box">
                                                <Link to={process.env.PUBLIC_URL + "/cart"} className="nav-link nav-cart">
                                                    <i className="las la-shopping-bag"></i>
                                                </Link>
                                            </li> */}
                                            <li className="nav-item side-box">
                                                <Sidebar />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Sticky Menu */}
                <StickyMenu />

                {/* Mobile Menu */}
                <MobileMenu />
            </Styles>
        )
    }
}

export default Header
