const Object = require('./class/Object');

const getValue = (obj, campo,valueDefault='')=>{
    let c = new Object;
    return c.getValue(obj,campo,valueDefault);
}

module.exports = {
    getValue,
}



