import React, { Component, useEffect } from 'react';
import { Styles } from '../styles/courseCategory.js';
import actions from '@iso/redux/pageHome/actions';
import actionsCursos from '@iso/redux/cursos/actions';
import { useDispatch, useSelector } from 'react-redux';

function CourseCategory(props){
    const dispatch = useDispatch();
    const categorias = useSelector(state => state.PageHome.categorias);
    const wasLoading = useSelector(state => state.PageHome.wasLoading);
    const filter = useSelector(state => state.Cursos.filter);
    
    useEffect(() => {
        dispatch(actions.verifyLoadFromFireStore(wasLoading))
        dispatch(actionsCursos.filterReset())
    },[]);

    const onHandle = (val,check)=>{
        dispatch(actionsCursos.filter(val,check))
    }

    

    const showCategorias=(dt)=>{
        let components;
        if(Object.keys(dt).length >0){
            components = Object.keys(dt).map((kdata, index) => {
                return(
                        // <li className="check-btn">
                        //     <label htmlFor="cat1"><input type="checkbox" id="cat1" className="check-box" />Web Development<span>(25)</span></label>
                        // </li>
                        <li className="check-btn" key={"cat"+kdata}>
                            <label htmlFor={dt[kdata]['categoria']}><input type="checkbox" checked={filter[dt[kdata]['categoria']]} value={dt[kdata]['categoria']} id={dt[kdata]['categoria']} className="check-box" onChange={event=>onHandle(kdata, event.target.checked)}/>{dt[kdata]['categoria']}<span></span></label>
                        </li>
                )
            })
        }
        return (
            <ul className="category-item list-unstyled">
            {components}
            </ul>);
    }


    return (
        <Styles>
            {/* Course Tag */}
            <div className="course-category">
                <h5>Áreas de Atuação</h5>
                {showCategorias(categorias)}
            </div>
        </Styles>
    )
    
}

export default CourseCategory
