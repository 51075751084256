import { db } from '@iso/lib/firebase/firebase';
import firebase from 'firebase';
import axios from 'axios';
import qs from 'qs';
import obj from 'nfjjs/obj';

const COLLECTION_NAME = 'perguntas';

export const getContatos = ()=>{
    return new Promise((resolve,reject)=>{
        db.collection(COLLECTION_NAME).get().then((querySnapshot) => {
            let dt = {};
            querySnapshot.forEach((doc) => {
                dt[doc.id]=doc.data()
            });
            resolve(dt);
        }).catch(error=>{
            reject(error)
        })
    })
}

export const saveDb = (dt)=>{
    return new Promise((resolve,reject)=>{
        addStatistic(dt['emailProfessor'],dt['idCurso']||'n',dt['curso']||dt['nomeCurso'],dt['emailAluno'])
        .then(_=>{
            getAutoId()
            .then(autoId=>{
                let docRef = db.collection(COLLECTION_NAME).add({
                    nomeAluno:obj.getValue(dt,'nomeAluno'), 
                    pergunta:obj.getValue(dt,'pergunta'), 
                    idModulo:obj.getValue(dt,'idModulo'), 
                    idCurso:obj.getValue(dt,'idCurso'), 
                    assunto:obj.getValue(dt,'assunto'), 
                    nomeAula:obj.getValue(dt,'nomeAula'), 
                    nomeCurso:obj.getValue(dt,'curso'),
                    emailProfessor:obj.getValue(dt,'emailProfessor'),
                    emailAluno:obj.getValue(dt,'emailAluno'), 
                    pergunta:obj.getValue(dt,'pergunta'), 
                    data:firebase.firestore.FieldValue.serverTimestamp(), 
                    status:'aberto',
                    repondido:false, 
                    id:autoId.idAuto
                },{merge:true})
                .then((docRef) => {
                    sendQuestion(docRef.id);
                    resolve(docRef);
                })
                .catch((error) => {
                    reject(error)
                });
            
            })
            .catch((error) => {
                reject(error)
            })
        })
    })
}

export const saveRespostaDb = (emailProfessor, idCurso, emailAluno, idPergunta, resposta, respondido,nome)=>{
    return new Promise((resolve,reject)=>{
        addStatisticAnswer(emailProfessor,idCurso, emailAluno,1, respondido).then(_=>{
            let id = Date.now();
            db.collection(COLLECTION_NAME).doc(idPergunta).set(
                {
                    respostas:{
                        [id]:{
                            data:firebase.firestore.FieldValue.serverTimestamp(),
                            resposta,
                            tipo:'resposta',
                            nome:nome
                        }
                    },
                    respondido:true
                },{merge:true})
            .then((docRef) => {
                sendAnswer(idPergunta);
                resolve(docRef);
            }).catch((error) => {reject(error)});
        })
    })
}

export const savePerguntaDb = (emailProfessor, idCurso, emailAluno, idPergunta, pergunta, respondido,nome)=>{
    return new Promise((resolve,reject)=>{
        let id = Date.now();
        addStatisticAnswer(emailProfessor,idCurso, emailAluno,-1, respondido).then(_=>{
            db.collection(COLLECTION_NAME).doc(idPergunta).set(
                {
                    respostas:{
                        [id]:{
                            data:firebase.firestore.FieldValue.serverTimestamp(),
                            resposta:pergunta,
                            tipo:'pergunta',
                            nome:nome
                        },
                    },
                    respondido:false
                
                },{merge:true})
            .then((docRef) => {
                resolve(docRef);
            }).catch((error) => {reject(error)});

        })
        
    })

}

const addStatistic=(email='',cursoId='', cursoName='',emailAluno='')=>{
    return new Promise((resolve,reject)=>{
        if(!email){
            email='no_professor';
        }
        let data = {
            "t_perguntas": firebase.firestore.FieldValue.increment(1),
            nameCursos:{
                [cursoId]:cursoName
            },
            alunos:{
                [emailAluno]:firebase.firestore.FieldValue.increment(1)
            },
            professores:{
                [email]:firebase.firestore.FieldValue.increment(1)
            },
            cursos:{
                [cursoId]: firebase.firestore.FieldValue.increment(1),
            },
            "idAuto": firebase.firestore.FieldValue.increment(1)
          }
        db.collection('statistic').doc('perguntar').set(data,{merge:true}).then((docRef) => {
                resolve(data);
        }).catch((error) => {reject(error)});
    })
}

const addStatisticAnswer=(emailProfessor='',cursoId='', emailAluno='',q=1, respondido=false)=>{
    return new Promise((resolve,reject)=>{
        if(respondido && q==1){
            resolve(true);
            return;
        }else if(!respondido && q==-1){
            resolve(true);
            return;
        }
        if(!emailProfessor){
            emailProfessor='no_professor';
        }

        if(!cursoId){
            cursoId='no_id';
        }
        if(q>0){
            db.collection('statistic').doc('perguntar').set({
                "t_perguntas": firebase.firestore.FieldValue.increment(1),
                respostas_alunos:{
                    [emailAluno]:firebase.firestore.FieldValue.increment(1)
                },
                respostas_professores:{
                    [emailProfessor]:firebase.firestore.FieldValue.increment(1)
                },
                respostas_cursos:{
                    [cursoId]: firebase.firestore.FieldValue.increment(1),
                }
              },{merge:true}).then((docRef) => {
                resolve(docRef);
            }).catch((error) => {reject(error)});
        }else if(q<1){
            db.collection('statistic').doc('perguntar').set({
               
                respostas_alunos:{
                    [emailAluno]:firebase.firestore.FieldValue.increment(-1)
                },
                respostas_professores:{
                    [emailProfessor]:firebase.firestore.FieldValue.increment(-1)
                },
                respostas_cursos:{
                    [cursoId]: firebase.firestore.FieldValue.increment(-1),
                }
              },{merge:true}).then((docRef) => {
                resolve(docRef);
            }).catch((error) => {reject(error)});
        }
       
    })
}

const getAutoId = ()=>{
    return new Promise((resolve,reject)=>{
        db.collection('statistic').doc('perguntar').get().then((dt) => {
            resolve({...dt.data()});
        }).catch(error=>{
            reject(error)
        })
    })
}

const subStatistic=(email='',cursoId='', emailAluno='')=>{
    return new Promise((resolve,reject)=>{
        if(!email){
            email='no_professor';
        }
        db.collection('statistic').doc('perguntar').set({
            "t_perguntas_resp": firebase.firestore.FieldValue.increment(1),
            alunos_resp:{
                [emailAluno]:firebase.firestore.FieldValue.increment(1)
            },
            professores_rep:{
                [email]:firebase.firestore.FieldValue.increment(1)
            },
            cursos_resp:{
                [cursoId]: firebase.firestore.FieldValue.increment(1),
            }
          },{merge:true}).then((docRef) => {
            resolve(docRef)
        }).catch((error) => {reject(error)});
    })
}

export const getStatistic=()=>{
    return new Promise((resolve,reject)=>{
        let documentRef = db.doc('statistic/perguntar');
        documentRef.get().then((doc) => {
            if(doc.exists){
                resolve(doc.data());
            }else{
                resolve(doc)
            }
        }).catch(error=>{
            reject(error)
        })
    })

}

export const getPerguntas=(emailProfessor='', emailAluno='', idCurso='', pessoa='',respondida=false, enviada=false)=>{
    return new Promise((resolve,reject)=>{
        let ref = db.collection(COLLECTION_NAME);
        if(pessoa=='instrutor'){
            ref = ref.where("emailProfessor", "==", emailProfessor);
        }

        if(pessoa=='aluno'){
            ref = ref.where("emailAluno", "==", emailAluno);
        }

        if(idCurso!=''){
            ref = ref.where("idCurso", "==", idCurso);
        }
        
        ref.get()
        .then((querySnapshot) => {
            let dt = {};
            querySnapshot.forEach((doc) => {
                dt[doc.id]={...doc.data(), idPergunta:doc.id}
            });
            resolve(dt);
        }).catch(error=>{
            reject(error)
        })
    })

}



export const updateDb = (k,dt)=>{
    return new Promise((resolve,reject)=>{
        const ref = db.collection(COLLECTION_NAME).doc(k);
        ref.update({dt},{merge:true})
        .then((docRef) => {resolve(docRef)})
        .catch((error) => {reject(error)});
    })
}


export const sendQuestion = (idPergunta) => {
    return axios({
        method: 'post',
        url: process.env.REACT_APP_WEBSERVICE+"nfj/sendQuestion",
        data: qs.stringify({
          idPergunta: idPergunta
        }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}

export const sendAnswer = (idPergunta) => {
    return axios({
        method: 'post',
        url: process.env.REACT_APP_WEBSERVICE+"nfj/sendAnswer",
        data: qs.stringify({
          idPergunta: idPergunta
        }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}

export const delDb = (k)=>{

}