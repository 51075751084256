import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import omit from 'lodash/omit';
import { rsf} from '@iso/lib/firebase/firebase';
import { push } from 'react-router-redux';


function* load() {
  try {
   
  } catch (error) {
    
  }
}

function* add({payload}) {
  try {
   if(payload.redirect!=''){
      yield put(push(payload.redirect));
   }
  } catch (error) {
    
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD,load),
    takeEvery(actions.ADD,add),
  ]);
}
