import { all, takeEvery, put, call} from 'redux-saga/effects';
import { eventChannel } from "redux-saga";
import actions from './actions';
import omit from 'lodash/omit';
import actionsCredencial from '../credencial/actions';
import { rsf, db } from '@iso/lib/firebase/firebase';
import notifications from '@iso/components/Feedback/Notification';

const COLLECTION_NAME = 'cad_perfil'; // change your collection
const ORDER_BY = 'id';
const ORDER = 'nome';

function* loadFromFirestore() {
  try {
    const snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME);
    let data;
    snapshots.forEach(user => {
        data = {
          ...data,
          [user.id]: user.data()
        }
    });
    // let data = yield call(convertCollectionsSnapshotToMap, snapshots);
    
    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error)
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName } = payload;
  const fdata = {...omit(data, ['file'])}
  try {
    yield call(uploadFile,'public/instrutor/'+data.email,data.file)
    switch (actionName) {
      case 'delete':
        yield call(
          rsf.firestore.deleteDocument,
          `${COLLECTION_NAME}/${data.key}`);
          yield put(actionsCredencial.saveIntoFireStore({email:data.email, instrutor:false},'delete'));
        break;
      case 'update':
        yield call(
          rsf.firestore.setDocument,
          `${COLLECTION_NAME}/${data.key}`,
          {
            ...omit(fdata, ['key']),
          }
        );
        break;
      default:
        yield call(rsf.firestore.addDocument, COLLECTION_NAME, fdata);
        yield put(actionsCredencial.saveIntoFireStore({email:data.email,instrutor:true},'insert'));
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE });
    yield call(notifications.success,{
      message: 'Perfil salvo com sucesso!',
      description:
        '',
    });
  } catch (error) {
    console.log(error);
    yield call(notifications.error,{
      message: 'Erro',
      description:
          'Houve um erro ao tentar salvar o perfil!',
    });
    yield put(actions.saveIntoFireStoreError(error));
  }
}

function* uploadFile(path, file) {
  const task = rsf.storage.uploadFile(path, file);
  console.log(task,path);
  const channel = eventChannel(emit => task.on('state_changed', emit));
  // yield takeEvery(channel, );
  // Wait for upload to complete
  yield task
  // Do something on complete
}

// function* resetFireStoreDocuments() {
//   try {
//     yield call(deleteDocuments, COLLECTION_NAME);
//     yield call(addCollectionAndDocuments, COLLECTION_NAME, fakeDataList);
//     yield put({ type: actions.LOAD_FROM_FIRESTORE });
//   } catch (error) {
//     console.log(error);
//   }
// }

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
    // takeEvery(actions.RESET_FIRESTORE_DOCUMENTS, resetFireStoreDocuments),
  ]);
}
