import { all, takeEvery, put, call} from 'redux-saga/effects';
import { eventChannel } from "redux-saga";
import actions from './actions';
import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';
import axios from 'axios'
import fileDownload from 'js-file-download'
import notifications from '@iso/components/Feedback/Notification';

const COLLECTION_NAME = 'cad_cursos'; // change your collection

function* loadFromFirestore({ payload }) {
  try {
    const snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME+"/"+payload.email+"/cursos");
    let data;
    snapshots.forEach(user => {
        data = {
          ...data,
          [user.id]: user.data()
        }
    });
    
    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error)
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* loadAnexos({ payload }) {
  try {
    const snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME+"/"+payload.email+"/cursos/"+payload.key+"/arquivos/");
    let data;
    let anexos={};
    snapshots.forEach(dt => {
        data = {
          ...data,
          [dt.id]: dt.data()
        }
    });
    anexos[payload.key]=data;
    yield put(actions.loadAnexosSuccess(anexos));
  } catch (error) {
    console.log(error)
    yield put(actions.loadAnexosError(error));
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName } = payload;
  const fdata = {...omit(data, ['file'])}
  try {
    // yield call(uploadFile,'private/curso/'+data.email,data.file)
    switch (actionName) {
      case 'delete':
        yield call(
          rsf.firestore.deleteDocument,
          `${COLLECTION_NAME}/${payload.email}/cursos/${data.key}`);
        break;
      case 'update':
        yield call(
          rsf.firestore.setDocument,
          `${COLLECTION_NAME}/${payload.email}/cursos/${data.key}`,
          {
            ...omit(fdata, ['key']),
          }
        );
        if(data.file){
          yield call(uploadFiles,'cqc/cursos/'+payload.email+'/'+data.key+"/",data.file,data.key, payload.email)
          yield put({ type: actions.LOAD_ANEXOS, payload:{email:payload.email, key:data.key}});
        }
        if(data.published){
          yield call(rsf.firestore.setDocument,`cursos/${data.key}`,{...fdata, email:payload.email, key:data.key},{merge:true})
        }
        break;
      default:
        const key = yield call(rsf.firestore.addDocument, `${COLLECTION_NAME}/${payload.email}/cursos`, fdata);
        if(data.file){
          yield call(uploadFiles,'cqc/cursos/'+payload.email+'/'+key.id+"/",data.file,key.id, payload.email)
          yield put({ type: actions.LOAD_ANEXOS, payload:{email:payload.email, key:key.id}});
        }
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE, payload:{email:payload.email}});
    if(actionName!='delete'){
      yield call(notifications.success,{
        message: 'Curso salvo com sucesso!!!',
        description:
          '',
      });
    }else{
      yield call(notifications.success,{
        message: 'Curso deletado com sucesso!!!',
        description:
          '',
      });
    }
  } catch (error) {
    console.log(error);
    yield call(notifications.error,{
      message: 'Erro',
      description:
          'Houve um erro ao tentar salvar a informação!',
    });
    yield put(actions.saveIntoFireStoreError(error));
  }
}

function* uploadFiles(path, file,key,email) {
  if(file.length >0){
    yield all(file.map(f=>{
      return call(uploadFile,path+f.name,f,key,f.name,email)
    }))
  }
}

function* uploadFile(path, file,key,fileName,email) {
  const task = rsf.storage.uploadFile(path, file);
  const channel = eventChannel(emit => task.on('state_changed', emit));
  yield task
  yield call(updatePathUpload,path,key,fileName,email)
}

function* updatePathUpload(path,key,fileName,email){
  yield call(
    rsf.firestore.addDocument,
    `${COLLECTION_NAME}/${email}/cursos/${key}/arquivos`,
    {
      "caminho":path,
      "fileName":fileName
    }
  );
}

function* downloadFile(data) {
  try{
    const url = yield call(rsf.storage.getDownloadURL, data.payload.caminho);
    
    yield call(handleDownload, url,data.payload.fileName);
  }catch(erro){
    console.log(erro)
  }
  
}

function* handleDownload(url, filename){
  axios.get(url, {
    responseType: 'blob',
  })
  .then((res) => {
    fileDownload(res.data, filename)
  })
}

function* deleteAnexo({payload}) {
  try{
    if(payload.path && payload.keyAnexo){
      yield call(rsf.firestore.deleteDocument, COLLECTION_NAME+"/"+payload.path+payload.keyAnexo);
      let s = payload.path.split("/");
      yield put({type: actions.LOAD_ANEXOS, payload:{email:s[0],key:s[2]}});
      // yield call(loadAnexos,{payload:{email:s[0],key:s[2]}})
    }
  }catch(erro){
    console.log(erro)
  }
  
  
}

function* publishCourse({payload}) {
  try{
    const { email, key, data } = payload;
    let newData;
    if(key){
      newData = {...data, email, key}
      yield call(rsf.firestore.setDocument, "cursos/"+payload.key, {...omit(data, ['link_fora']), email, key}, {merge:true});
      yield call(rsf.firestore.setDocument, `${COLLECTION_NAME}/${email}/cursos/${key}`, {published:true},{merge:true});
      yield put({type: actions.LOAD_FROM_FIRESTORE, payload:{email}});
    }
  }catch(erro){
    console.log(erro)
  }
}

function* unpublishCourse({payload}) {
  try{
    const { email, key, data } = payload;
    let newData;
    if(key){
      newData = {...data, email, key}
      yield call(rsf.firestore.deleteDocument, "cursos/"+payload.key);
      yield call(rsf.firestore.setDocument, `${COLLECTION_NAME}/${email}/cursos/${key}`, {published:false},{merge:true});
      yield put({type: actions.LOAD_FROM_FIRESTORE, payload:{email}});
    }
  }catch(erro){
    console.log(erro)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
    takeEvery(actions.DOWNLOAD, downloadFile),
    takeEvery(actions.LOAD_ANEXOS, loadAnexos),
    takeEvery(actions.DELETE_ANEXO, deleteAnexo),
    takeEvery(actions.PUBLISH_COURSE, publishCourse),
    takeEvery(actions.UNPUBLISH_COURSE, unpublishCourse),
  ]);
}
