import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';
import notifications from '@iso/components/Feedback/Notification';

function* saveContact({ payload }) {
  const { data, actionName } = payload;
  try {
    
    yield call(rsf.firestore.addDocument, 'contact', {...omit(data, ['emailNewsletter','nomeNewsletter'])});

    yield put({ type: actions.SAVE_CONTACT_SUCCESS});
    yield call(notifications.success,{
      message: 'Mensagem enviada com sucesso!',
      description:
        '',
    });
  }catch (error) {
    console.log(error);
    yield put(actions.SAVE_CONTACT_ERROR);
    yield call(notifications.error,{
      message: 'Erro',
      description:
          'Erro ao tentar enviar mensagem!',
  });
  
  }
}

function* saveNewsletter({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {  
      default:
        yield call(rsf.firestore.addDocument, 'newsletter', 
        {...omit(data, ['nome','assunto','email','mensagem'])});
        break;
    }
    yield put({ type: actions.SAVE_NEWSLETTER_SUCCESS});
    yield call(notifications.success,{
      message: 'Cadastro Efetuado com sucesso!',
      description:
        '',
    });
  } catch (error) {
    console.log(error);
    yield put(actions.SAVE_NEWSLETTER_ERROR);
    yield call(notifications.error,{
      message: 'Erro',
      description:
          'Erro ao tentar Cadastrar!',
  });
  
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SAVE_CONTACT, saveContact),
    takeEvery(actions.SAVE_NEWSLETTER, saveNewsletter),
  ]);
}
