import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { rsf, db} from '@iso/lib/firebase/firebase';
import axios from 'axios'
import firebase from 'firebase';

const COLLECTION_NAME = 'hotmart_assinatura';

//Faz a verificação se há pagamento de assinaturas ou produtos
function* check({payload}) {
  try {
    yield call(checkAssinatura, {payload:{email:payload.email}});
    yield call(checkProdutos, {payload:{email:payload.email}});
    yield put({ type: actions.CHECK_SUCCESS, payload:{payload}});
  } catch (error) {
    yield put({ type: actions.CHECK_ERROR, payload:{error}});
  }
}

//Verifica se há assinatura
function* checkAssinatura({ payload }) {
  try {
    let email = payload.email;
    const dt = yield call(axios.post,process.env.REACT_APP_WEBSERVICE+"hotmart/checkAssinatura",{
      email:payload.email,
    })
    let data = dt.data;
    let dtA={};
    if(data){
      if(data.data.page_info.total_results>0){
        data.data.items.map((values,key)=>{
          dtA[values['subscription_id']]={...values};
        })
        yield call(rsf.firestore.setDocument, COLLECTION_NAME+"/"+payload.email, {
          ...dtA,
        }, { merge: true });
      }
      yield put({ type: actions.CHECK_ASSINATURA_SUCCESS, payload:{assinaturas:dtA}});
    }
  } catch (error) {
    yield put({ type: actions.CONCLUIR_PEDIDO_ERROR, payload:{error}});
    console.log(error);
  }
}

//Checa o produto se houve o pagamento
function* checkProduto({ payload }) {
  try {
    let email = payload.email;
    const dt = yield call(axios.post,process.env.REACT_APP_WEBSERVICE+"hotmart/checkAssinatura",{
      email:payload.email,
    })
    let data = dt.data;
    let dtA={};
    if(data.data.page_info.total_results>0){
      data.data.items.map((values,key)=>{
        dtA[values['subscription_id']]={...values};
      })
      yield call(rsf.firestore.setDocument, COLLECTION_NAME+"/"+payload.email, {
        ...dtA,
      }, { merge: true });
    }
    yield put({ type: actions.CHECK_ASSINATURA_SUCCESS, payload:{assinaturas:dtA}});
  } catch (error) {
    yield put({ type: actions.CONCLUIR_PEDIDO_ERROR, payload:{error}});
    console.log(error);
  }
}

//Checa se houve pagamentos somente consultando o e-mail
function* checkProdutos({ payload }) {
  try {
    let email = payload.email;
    const dt = yield call(axios.post,process.env.REACT_APP_WEBSERVICE+"hotmart/checkProdutos",{
      email:payload.email,
    })
    let data = dt.data;
    let dtA={};
    if(data.data.page_info.total_results>0){
      data.data.items.map((values,key)=>{
        dtA[values['transaction']]={...values};
      })
      console.log(dtA);
      // yield call(rsf.firestore.setDocument, "hotmart_produtos/"+payload.email, {
      //   ...dtA,
      // }, { merge: true });
      // yield call(vinculaVendas, payload.email,dtA);
    }
    yield put({ type: actions.CHECK_PRODUTOS_SUCCESS, payload:{produtos:dtA}});
  } catch (error) {
    yield put({ type: actions.CHECK_PRODUTOS_ERROR, payload:{error}});
    console.log(error);
  }
}

//Faz a vinculação das vendas pelo e-mail checado
// function* vinculaVendas(email, array) {
//   try{
//     const collectionRef = db
//     .collection("hotmart_historico/")
//     .where("email","==",email)

//     const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
//     let dtA={};
//     let exec={};

//     snapshots.forEach(doc => {
//        dtA[doc.key] = doc.data();
//     }); 

//     Object.keys(array).map((values)=>{
//       if(!dtA[values]){
//         exec[values]=array[values];
//       }
//     })

//     console.log(exec)

//     Object.keys(exec).map(transaction => {
//       console.log(transaction)
//       // return call(checkTransaction, email, transaction)  
//     })

//   }catch (error) {
//     console.log(error, 'ao tentar vincular contas');
//   }

// }

//Checa uma transação e salva no BD
// function* checkTransaction(email, transaction) {
//   try {

//     console.log(email, transaction);
//     const dt = yield call(axios.post,process.env.REACT_APP_WEBSERVICE+"hotmart/checkHistoryTransaction",{
//       transaction:transaction,
//     })
//     let data = dt.data;
//     let dtA={};
//     if(data.data.page_info.total_results>0){
//       data.data.items.map((values,key)=>{
//         dtA[values['transaction']]={...values};
//       })
//       console.log(dtA)
//     }
//     yield put({ type: actions.CHECK_PRODUTOS_SUCCESS, payload:{produtos:dtA}});
//   } catch (error) {
//     yield put({ type: actions.CHECK_PRODUTOS_ERROR, payload:{error}});
//     console.log(error);
//   }
// }

//Checa o histórico e salva no bd
// function* checkHistorico({ payload }) {
//   try {
//     let transaction = payload.transaction;
    
//     const dt = yield call(axios.post,process.env.REACT_APP_WEBSERVICE+"hotmart/checkProdutos",{
//       email:payload.email,
//     })
//     let data = dt.data;
//     let dtA={};
//     if(data.data.page_info.total_results>0){
//       data.data.items.map((values,key)=>{
//         dtA[values['transaction']]={...values};
//       })
//       yield call(rsf.firestore.setDocument, "hotmart_produtos/"+payload.email, {
//         ...dtA,
//       }, { merge: true });
//     }
//     yield put({ type: actions.CHECK_PRODUTOS_SUCCESS, payload:{produtos:dtA}});
//   } catch (error) {
//     yield put({ type: actions.CHECK_PRODUTOS_ERROR, payload:{error}});
//     console.log(error);
//   }
// }


//Checa o histórico e salva no bd
// function* checkHistoricos({ payload }) {
//   try {
//     let email = payload.email;
//     const dt = yield call(axios.post,process.env.REACT_APP_WEBSERVICE+"hotmart/checkProdutos",{
//       email:payload.email,
//     })
//     let data = dt.data;
//     let dtA={};
//     if(data.data.page_info.total_results>0){
//       data.data.items.map((values,key)=>{
//         dtA[values['transaction']]={...values};
//       })
//       yield call(rsf.firestore.setDocument, "hotmart_produtos/"+payload.email, {
//         ...dtA,
//       }, { merge: true });
//     }
//     yield put({ type: actions.CHECK_PRODUTOS_SUCCESS, payload:{produtos:dtA}});
//   } catch (error) {
//     yield put({ type: actions.CHECK_PRODUTOS_ERROR, payload:{error}});
//     console.log(error);
//   }
// }

//Faz o lançamento como pedido aberto - DESCONTINUADO POIS PODERÁ COMPRAR FORA DA PLATAFORMA
function* lancaPedido({payload}) {
  try {
    // Cadastra Pedido aberto
    let fdata={};
    fdata['idCurso']=payload.idCurso;
    fdata['tipo']=payload.tipo;//Mensalidade ou assinatura
    fdata['data']=firebase.firestore.FieldValue.serverTimestamp()
    fdata['status']='aberto';
    yield call(rsf.firestore.setDocument, "hotmart_pedido/"+payload.email+"/"+fdata['idCurso'], fdata, { merge: true });
    yield put({ type: actions.LANCA_PEDIDO_SUCCESS, payload:{fdata}});
  } catch (error) {
    yield put({ type: actions.LANCA_PEDIDO_ERROR, payload:{error}});
  }
}



// function* pedidoAberto({payload}) {
//   try {
//     // Busca os pedidos em aberto
//     const collectionRef = db
//       .collection('hotmart_pedido'+"/"+payload.email)

//       const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
//       let data;
//       snapshots.forEach(user => {
//           data = {
//             ...data,
//             [user.id]: user.data()
//           }
//       });
//       let curso={};
//       if(data){
//         curso[payload.idCurso]=data;  
//       }
//   } catch (error) {
    
//   }
// }

// function* consultaPagamento({payload}) {
//   try {
//     const dt = yield call(axios.post,process.env.REACT_APP_WEBSERVICE+"consultaPagamento",{
//       id:payload.id
//     })
//     let data = dt.data;

//     if(data.orders){
//       let pago=false;
//       let cancelado=false;
//       yield all (data.orders.map(doc=>{

//         if(doc.payment.status=='Authorized'){
//           pago = true;
//         }
//         if(pago && !cancelado){
//           return call(lancaPagamento, {payload:{email:payload.email,produtos:payload.produtos,id:payload.id,pagamento:doc, indicacao:payload.indicacao}})
//         }else if(cancelado){
//           return call(lancaCancelamento, {payload:{email:payload.email,id:payload.id,pagamento:doc}})
//         }
//       })
//       )
//     }
//   } catch (error) {
//     console.log(error)
//   }
  
// }

// function* lancaPagamento({payload}){
//   try {
//     yield call(lancaAssinatura, {payload})
       
//   } catch (error) {
//     console.log(error)
//   }
// }


//Faz o lançamento da assinatura,
//Colocar para verificar o tipo do produto se é assinatura
// function* lancaAssinatura({payload}){
//   try {
//     const email = payload.email;
//     let dt1, dt2, assinatura, validade
//     assinatura=false
//     let diasAcesso=0;

//     //Retorna sessão atual de último acesso
//     const snapshot = yield call(rsf.firestore.getDocument, 'log/'+email);
//     const log = snapshot.data();
//     dt1 = log.currentAccess.toDate()

//     const collectionRef = db
//         .collection("assinaturas/")
//         .where("email","==",email)

//       const snapshotsAssinatura = yield call(rsf.firestore.getCollection, collectionRef);
      
//       snapshotsAssinatura.forEach(dt => {
//         dt2 = new Date(dt.data().validade)
//         if(!assinatura){
//           if(dt1.getTime()<=dt2.getTime()){
//             assinatura=true
//             diasAcesso+=Math.ceil((dt2.getTime()-dt1.getTime())/(1000 * 60 * 60 * 24))
//           }
//         }
//     });


//     if(Object.keys(payload.produtos).length>0){
//       yield all(Object.keys(payload.produtos).map((kdata, index) => {
//           if(kdata=='assinaturaAnual'){
//             validade = dt1.setDate(parseInt(dt1.getDate())+parseInt(diasAcesso)+parseInt(payload.produtos[kdata].dias_acessos)).valueOf();
//             return call(rsf.firestore.setDocument, 'assinaturas/'+payload.id, {
//               email: email,
//               validade: validade,
//               idLink: payload.id,
//               orderNumber: payload.pagamento.orderNumber,
//               price: payload.pagamento.payment.price,
//               numberOfPayments: payload.pagamento.payment.numberOfPayments,
//               createdDate: payload.pagamento.createdDate,
//               status: payload.pagamento.payment.status,
//               assinatura:'ativa'
//             });
//           }
//         })
//       ) 
//     }

//     yield call(rsf.firestore.setDocument, 'pedidos/'+payload.email+"/pedido/"+payload.id, {
//         status:'concluido'
//     },{merge:true});

//     yield call(rsf.firestore.deleteDocument, COLLECTION_NAME+"_aprocessar/"+payload.id);

//     yield put({ type: 'CREDENCIAL_LOAD_ASSINATURA', payload:{email}});

//     yield call(lancaOrderPagamento,{payload: {email, orderNumber:payload.pagamento.orderNumber, indicacao:payload.indicacao}})
//   } catch (error) {
//     console.log(error)
//   }
// }


//Colocar aqui a indicação
// function* lancaOrderPagamento({payload}){
//   const dt = yield call(axios.post,process.env.REACT_APP_WEBSERVICE+"consultaOrderPagamento",{
//     id:payload.orderNumber,
//   })
//   let data = dt.data;


//     console.log({indicacao:payload.indicacao,
//       email:payload.email})
//   yield call(rsf.firestore.setDocument, COLLECTION_NAME+"_faturar/"+payload.orderNumber, {
//     ...data,
//     // indicacao:payload.indicacao,
//     email:payload.email,
//     status:'aberto',
//     data:firebase.firestore.FieldValue.serverTimestamp(),
//   }, { merge: true });
// }

// //Colocar código para cancelamento
// function* lancaCancelamento({payload}){
//   try {


//   } catch (error) {
//     console.log(error)
//   }
// }



//Consulta vários pagamentos aberto pelo cliente... 
//Colocar parametro para excluir pedidos em aberto e desativar
// function* consultaPagamentos({payload}) {
//   try {
//     const collectionRef = db
//         .collection(COLLECTION_NAME+"/"+payload.email+"/pedido/")
//         .where("status","==",'aberto')

//         const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
//         let dataFb;
//         if(snapshots){
//           yield all( 
//             snapshots.docs.map(dt => {
//               dataFb = dt.data()
//              return call(consultaPagamento, {payload:{email:payload.email,id:dt.id,produtos:dataFb.produtos,...payload}})
//           }))
//         }
       
//   } catch (error) {
//     console.log(error)
//   }
// }

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHECK_ASSINATURA, checkAssinatura),
    takeEvery(actions.CHECK_PRODUTO, checkProduto),
    takeEvery(actions.LANCA_PEDIDO, lancaPedido),
    takeEvery(actions.CHECK, check),
  ]);
}
