import React, { useEffect } from 'react';
import Datas from '../data/footer/footer2.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BackToTop from './common/BackToTop';
import { Styles } from "./styles/footerTwo.js";
import {siteConfig} from '../settings';
import { useDispatch, useSelector} from 'react-redux';
import actions from '@iso/redux/contact/actions';

function FooterTwo() {
    const dispatch = useDispatch();
    const { fdata, enbaledButtonNewsletter, buttonNewsletter } = useSelector(
        state => state.Contact
      );

    const onRecordChange = (event, key) => {
    if (key) fdata[key] = event.target.value;
    dispatch(actions.updateContact(fdata));
    };

    useEffect(() => {
        (() => {
            if (!localStorage.cqcCookies) {
              document.querySelector(".box-cookies").classList.remove('hide');
            }
            
            const acceptCookies = () => {
              document.querySelector(".box-cookies").classList.add('hide');
              localStorage.setItem("cqcCookies", "accept");
            };
            
            const btnCookies = document.querySelector(".btn-cookies");
            
            btnCookies.addEventListener('click', acceptCookies);
          })();
        const form = document.getElementById("form4");
        const email = document.getElementById("email4");

        form.addEventListener("submit", formSubmit);

        function formSubmit(e) {
            e.preventDefault();
            let ok = true;

            const emailValue = email.value.trim();

            if (emailValue === "") {
                setError(email, "E-mail está em branco");
                ok=false;
            } else if (!isEmail(emailValue)) {
                setError(email, "E-mail Inválido");
                ok=false;
            } else {
                setSuccess(email);
            }
            
            if(ok){
                dispatch(actions.saveNewsletter(fdata));                
            }
        }

        function setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".input-msg4");
            formControl.className = "form-control error";
            errorMsg.innerText = message;
        }

        function setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }

        function isEmail(email) {
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        }
    },[]);

    return (
        <Styles>
            {/* Footer Two */}
            <footer className="footer2" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.backgroundImage})` }}>
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="footer-logo-info">
                                <img src={process.env.PUBLIC_URL + siteConfig.logo_171_41} alt="" className="img-fluid" />
                                {/* <p>Lorem ipsum dolor sit amet, consectet adipisicing elit. Saepe porro neque a nam null quos.</p> */}
                                <ul className="list-unstyled">
                                    <li><i className="las la-map-marker"></i>{siteConfig.addressFull}</li>
                                    <li><i className="las la-envelope"></i>{siteConfig.email}</li>
                                    <li><i className="las la-phone"></i>{siteConfig.fone}</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md="3">
                                <div className="f-links">
                                    <h5>Links</h5>
                                    <ul className="list-unstyled">
                                        <li><Link to="/termos"><i className="las la-angle-right"></i>Termos e Condições</Link></li>
                                        <li><Link to="/termos"><i className="las la-angle-right"></i>Políticas de Privacidade</Link></li>
                                        <li><Link to="/termos"><i className="las la-angle-right"></i>LGPD</Link></li>
                                    </ul>
                                    
                                </div>
                        </Col>
                        <Col md="3">
                           
                        </Col>
                        <Col md="3">
                            <div className="f-newsletter">
                                <h5>Newsletter</h5>
                                <p>Tenho interesse.</p>

                                <form id="form4" className="form">
                                    <p className="form-control">
                                        <input type="email" placeholder="Seu e-mail" value={fdata.emailNewsletter} onChange={e => onRecordChange(e, 'emailNewsletter')} id="email4" />
                                        <span className="input-msg4"></span>
                                    </p>
                                    <button disabled={!enbaledButtonNewsletter}>{buttonNewsletter}</button>
                                </form>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="copytext-area text-center">
                                {/* <p>&copy; {siteConfig.footerText} <a href={siteConfig.linkFooterText} target="_blank" rel="noopener noreferrer">{siteConfig.desenvolvedor}</a></p> */}
                            <p>Copyright &copy; 2021 Coopercont | Desenvolvido by <a href="https://www.nfj.com.br" target="_blank" rel="noopener noreferrer">NFJ</a></p>
                                <ul className="social list-unstyled list-inline">
                                <li className="list-inline-item"><a href={siteConfig.facebook}><i className="fab fa-facebook-f"></i></a></li>
                                    {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li> */}
                                    <li className="list-inline-item"><a href={siteConfig.linkedin}><i className="fab fa-linkedin-in"></i></a></li>
                                    <li className="list-inline-item"><a href={siteConfig.instagram}><i className="fab fa-instagram"></i></a></li>
                                    {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li> */}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* Back To Top  */}
                <BackToTop />

                 {/* Aceitar cookies */}
                 <div className="box-cookies hide">
                        <p className="msg-cookies">Este site utiliza de cookies para garantir que você obtenha a melhor experiência. Ao continuiar navegando você aceita os nossos <Link to="termos_e_servicos">termos e serviços</Link>
                        <button className="btn-cookies">Ok, Entendi!</button></p>
                    </div>
            </footer>
        </Styles>
    );
}

export default FooterTwo
