import React, { Component, Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import actions from '@iso/redux/pageHome/actions';
import { useDispatch, useSelector } from 'react-redux';
import ModalVideo from 'react-modal-video';
import ReactPlayer from 'react-player'
import actionsHotmart from '@iso/redux/hotmart/actions';
import { merge, filter } from 'lodash';
import money from 'nfjjs/money';



function CourseItemGrid(props) {
    const dispatch = useDispatch();

    const cursos = useSelector(state => state.PageHome.cursos);
    const instrutor = useSelector(state => state.PageHome.instrutores);
    const wasLoading = useSelector(state => state.PageHome.wasLoading);
    const prod = useSelector(state => state.PageHome.produtos);
    const {email, displayName} = useSelector(state => state.Auth);
    const [isOpen,setIsOpen]=useState(false);
    const {assinaturas, produtos, assinante} = useSelector(state => state.Hotmart);
    const {aluno} = useSelector(state => state.Credencial);
    const cursosContratados = useSelector(state => state.Credencial.cursosContratados);
    const filterCategoria = useSelector(state => state.Cursos.filter);
    const filterNome = useSelector(state => state.Cursos.filterNome);


    const Credencial = useSelector(state => state.Credencial);

    // const [isFilter, setIsFilter] =  useState(false);
    // const [filtred, setFiltred] =  useState('');
    const [q, setQ] =  useState(Object.keys(cursos).length);


    useEffect(() => {
        dispatch(actions.verifyLoadFromFireStore(wasLoading))
        dispatch(actionsHotmart.checkAssinatura(email))
    },[]);

    const nomeInstrutor=(email)=>{
        if(instrutor.hasOwnProperty(email)){
            if(instrutor[email].hasOwnProperty('nome')){
                return (instrutor[email]['nome'])
            }
        }else{
            return('')
        }
    }

    const urlImg=(email)=>{
        if(instrutor.hasOwnProperty(email)){
            if(instrutor[email].hasOwnProperty('urlFoto')){
                return (instrutor[email]['urlFoto'])
            }
        }else{
            return('')
        }

    }

    const isFiltred=(filterCategoria, filterNome)=>{
        let text = '';
        if(filterNome!=''){
            text = "Nome: "+filterNome;
        }

        if(text!=''){
            return (
                <>
                <div style={{width:'100%',padding:"10px"}}>
                    <Row>
                        <Col lg="6" md="6" >
                            <h4>{'Buscando cursos: '+filterNome}</h4>
                            <h4>{''}</h4>
                        </Col>
                    </Row>
                </div>
                </>
            )
        }
    }

    const showCursos=(filterCategoria, filterNome)=>{
        let cursosSearch = cursos;
        let filterCat = {};
        let filterCate = false;

        if(Object.keys(filterCategoria).length>0){
            
            Object.keys(filterCategoria).map(cat=>{
                if(filterCategoria[cat]){
                    filterCate = true;
                    filterCat = merge(filterCat, filter(cursosSearch,{'categoria':cat}));
                }
                // cursosSearch = filter(cursosSearch,{'categoria':cat})
                
            })
            // cursosSearch = filter(cursosSearch,option=>option.categoria.includes(filterCategoria))
        }

        

        if(filterNome!=''){
            // setFiltred(' '+filterNome+".");
            if(Object.keys(filterCat).length>0 || filterCate){
                cursosSearch = filter(filterCat,option=>option.curso.toLowerCase().includes(filterNome.toLowerCase()))
            }else{
                cursosSearch = filter(cursosSearch,option=>option.curso.toLowerCase().includes(filterNome.toLowerCase()))
            }
        }else{
            if(filterCate){
                cursosSearch =filterCat;
            }
        }

        


        return(Object.values(cursosSearch).map((data, i) => (
            <Col lg="6" md="12" key={data.key}>
                <div className="course-item">
                    {/* <Link to={process.env.PUBLIC_URL + data.courseLink}>
                        <div className="course-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${data.imgUrl})` }}>
                            <div className="author-img d-flex">
                                <div className="img">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/${data.authorImg}`} alt="" />
                                </div>
                                <div className="title">
                                    <p>{data.authorName}</p>
                                    <span>{data.authorCourses}</span>
                                </div>
                            </div>
                            <div className="course-price">
                                <p>{data.price}</p>
                            </div>
                        </div>
                    </Link> */}
                    <div className="course-content">
                        <h6 className="heading"><Link to={"/logged/curso/"+data.key}>{data.curso}</Link></h6>
                        {/* <div className="desc">{parse(data.descricao)}</div> */}
                        <div className="course-face d-flex justify-content-between">
                            
                            
                            
                            {/* 
                            <div className="rating">
                                <ul className="list-unstyled list-inline">
                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                    <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                    </li>
                                    <li className="list-inline-item">(4.5)</li>
                                </ul>
                            </div>
                            <div className="student">
                                <p><i className="las la-chair"></i>60</p>
                            </div> */}
                           
                           <div>
                            <p className="desc"><span >{nomeInstrutor(data.email)}</span></p>
                            </div>
                            <div>
                                
                            {showDuration(data.carga_horaria)}
                            </div>
                           
                        </div>

                        
                            {showTeaser(data['link_video_teaser'])}
                       
                        <div className="course-face d-flex justify-content-between">
                            
                        </div>
                        {showButton(data,aluno,produtos)}
                    </div>
                </div>
            </Col>
        ))
        )
    }

    const showDuration= (duration=0)=>{
        if(duration>0){
            return(<div className="duration">
                 <p><i className="las la-clock"></i>{duration} min</p>
            </div>)
        }
        
    }

    const showTeaserModal=(url='',channel='vimeo')=>{
        if(url!=''){
            return(
                <div className="video-player" align="center">
                    <ModalVideo channel={channel} isOpen={isOpen} videoId={url} onClose={() => setIsOpen(false)} />
                    <button onClick={() => setIsOpen(true)} className="play-button"><i className="las la-play"></i></button>
                </div>
            )
        }
    }

    const showTeaser=(url='',channel='vimeo')=>{
        if(url!=''){
            return(
                <>
                <div>
                    <ReactPlayer url={url} controls={true} width="100%"/>
                    </div>
                </>
            )
        }
    }

    const showButton=(data,assinante, cursos)=>{
        if(data){
            if(assinante){
                if(data.hasOwnProperty('key')){
                    return(<Link  to={"/logged/curso/"+data.key}><button>Visualizar</button></Link>)
                }
            }else if(cursosContratados[data.key]){
                return(<Link to={"/logged/curso/"+data.key}><button>Acessar Curso</button></Link>)
            }else{
                return(
                    <>
                     <p  align="right" style={{padding:"10px"}}><Link to={"/curso/"+data.key}>+ Saiba Mais</Link>
                        </p>
                            {/* <Link className="button" to={"/contratar/"+data.key}>Contratar curso individual</Link> */}
                        <Link to={"/contratar/"+data.key}><button>Contratar curso individual {showPrice('individual',data)}</button></Link>

                       
                        <Link to="/contratar"><button>Assinar todos os cursos {showPrice('assinatura')}</button></Link>
                    </>
    
                )
            }
           
           
        }
        // if(Credencial.aluno){
        //     if(data){
        //         if(data.hasOwnProperty('key')){
        //             return(<Link to={"/logged/curso/"+data.key}>Visualizar</Link>)
        //         }
        //     }
        // }else{
        //     return(<Link to="/contratar">Acessar</Link>)
        // }
        

    }

    const showPrice = (type='',data)=>{
        if(type=='individual'){

            if(typeof(data)=='object'){
                if(data.hasOwnProperty('valor')){

                    if(data.hasOwnProperty('valorBruto')){
                        if(data.valorBruto > data.valor){
                            return (<div><span style={{color:'black', textDecoration: "line-through"}}> DE {money.getValueUser(data.valorBruto)}</span> POR R$ {money.getValueUser(data.valor)}</div>)
                        }
                    }

                    if(data.hasOwnProperty('q_parcela')){
                        return ("R$ "+money.getValueUser(data.valor)+'.');
                    }else{
                        return ("R$ "+money.getValueUser(data.valor)+'.');
                    }
                }
            }
        }else if(type=='assinatura'){
            
            if(typeof(prod)=='object'){
                if(prod.hasOwnProperty('assinaturaMensal')){
                    return(
                        "R$ "+money.getValueUser(prod.assinaturaMensal.valor)+'/ mês.'
                    )
                }else{

                }

            }

        }

    }

    return (
        <>
            {isFiltred(filterCategoria, filterNome)}
            {showCursos(filterCategoria, filterNome)}
        </>
    )
    
}

export default CourseItemGrid
