const DOCUMENT = 'PERGUNTAR_';

const actions = {

  LOAD_PERGUNTAS: DOCUMENT + 'LOAD_PERGUNTAS',
  LOAD_PERGUNTAS_ERROR: DOCUMENT + 'LOAD_PERGUNTAS_ERRO',
  LOAD_PERGUNTAS_UPDATE: DOCUMENT + 'LOAD_PERGUNTAS_UPDATE',
  LOAD_PERGUNTAS_SUCCESS: DOCUMENT + 'LOAD_PERGUNTAS_SUCCESS',

  LOAD_ESTATISTICA: DOCUMENT + 'LOAD_ESTATISTICA',
  LOAD_ESTATISTICA_ERROR: DOCUMENT + 'LOAD_ESTATISTICA_ERRO',
  LOAD_ESTATISTICA_UPDATE: DOCUMENT + 'LOAD_ESTATISTICA_UPDATE',
  LOAD_ESTATISTICA_SUCCESS: DOCUMENT + 'LOAD_ESTATISTICA_SUCCESS',

  loadPerguntas: (emailProfessor,emailAluno,idCurso, pessoa='instrutor', respondido='') => ({
    type: actions.LOAD_PERGUNTAS,
    payload: { emailProfessor,emailAluno, idCurso,  pessoa, respondido },
  }),

  loadEstatistica: (emailProfessor,emailAluno, idCurso, pessoa='instrutor', respondido='') => ({
    type: actions.LOAD_ESTATISTICA,
    payload: { emailProfessor,emailAluno, idCurso,  pessoa, respondido },
  }),


  updateEstatistica: data => ({
    type: actions.LOAD_ESTATISTICA_UPDATE,
    payload: { data },
  }),

  updatePerguntas: data => ({
    type: actions.LOAD_PERGUNTAS_UPDATE,
    payload: { data },
  })
};
export default actions;

