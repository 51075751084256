import { all, takeEvery, put, fork, call, take } from 'redux-saga/effects';
import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';
import actions from './actions';
import firebase from 'firebase';
import axios from 'axios'

const COLLECTION_NAME = 'credencial';

export function* loadCredencial(payload) {
  const email = payload.email;
  if(email!=''){
    const snapshot = yield call(rsf.firestore.getDocument, 'credencial/'+email);
    const credencial = snapshot.data();
    yield put({ type: actions.LOAD_SUCCESS,payload:credencial});
    yield put({ type: actions.LOAD_ASSINATURA,payload:{email}});
  }else{
    yield put({ type: actions.LOAD_ERROR});
  }
}

//Faz a busca na API e depois
export function* loadProdutos({payload}) {
  try{
    const email = payload.email;
    if(email!=''){
      const dt = yield call(axios.post,process.env.REACT_APP_WEBSERVICE+"hotmart/consultaVendaEmail",{
        email:payload.email,
      })
    }

    


    // let dt1, dt2, assinatura
    // let diasAcesso=0;
  
    // try{
    //   const snapshot = yield call(rsf.firestore.getDocument, 'log/'+email);
    //   const log = snapshot.data();
    //   dt1 = log.currentAccess.toDate()
    //     //Verifica novas compras e salva no bd - via hotmart
    //     const dt = yield call(axios.post,process.env.REACT_APP_WEBSERVICE+"hotmart/consultaVendaEmail",{
    //       email:payload.email,
    //     })

    //     // if(dt.data){
    //       let cursosContratados={};
    //       let cursosAcontratar={};
    //       let cursos={};
    //       let data;

    //       const snapshotsCursos = yield call(rsf.firestore.getCollection, 'cursos/');
          
    //       snapshotsCursos.forEach(c => {
    //         data = c.data();
    //         if(data.published){
    //           cursos[data.id_hotmart]=data;
    //           cursos[data.id_hotmart]['uid']=c.id;
    //         }
    //       })

    //       cursosAcontratar=cursos;

    //       //Puxa as vendas do Banco de dados
    //       const snapshotsProdutos = yield call(rsf.firestore.getCollection, 'hotmart_transactions/vendas/'+email+'/');
    
    //       let purchase;
    //       let product;
    //       let productsApproved={};
    //       let alunoAvulso=false;
    //       let daysContract;
       
          
    //       //faz o tratamento para vinculação
    //       snapshotsProdutos.forEach(d => {
    //         data = d.data();
    //         purchase = data.purchase;
  
    //         if(purchase.status==='APPROVED'){
              
    //           product = data.product;
    //           dt2 = new Date(purchase.approved_date);

    //           daysContract = Math.ceil((dt1.getTime()-dt2.getTime())/(1000 * 60 * 60 * 24));
    //           productsApproved[d.id]={
    //             id:product.id,
    //             course:product.name,
    //             daysApproved:daysContract,
    //             dateApproved:dt2
    //           }

    //           if(cursos[data['product']['id']]){
    //             if(productsApproved[d.id].daysApproved<=cursos[data['product']['id']].dias_acesso){
    //               alunoAvulso=true;
    //               cursosContratados[cursos[data['product']['id']].uid]=
    //               {
    //                 ...cursos[data['product']['id']], 
    //                 isPlataform:true, 
    //                 id:cursos[data['product']['id']].uid,
    //                 idHotmart:data['product']['id']
    //               };
    //             }
    //           }else{
    //             //Lançar erro na plataforma para identificar que comprou um curso e não tem na plataforma
    //             cursosContratados[d.id]={
    //               curso: productsApproved[d.id].course,
    //               id: d.id,
    //               key:d.id,
    //               idHotmart: data['product']['id'],
    //               isPlataform:false
    //             };
    //           }
    //         }
    //       });
          yield call(loadProdutosBd,{payload:{email:payload.email}});
          // yield put({ type: actions.LOAD_PRODUTOS_SUCCESS, payload: {purchase:productsApproved, cursosAcontratar: cursosAcontratar, cursosContratados:cursosContratados, alunoAvulso}});
        // }
    }catch(error){
      console.log(error);
      yield call(loadProdutosBd,{payload:{email:payload.email}});
      yield put({ type: actions.LOAD_ERROR, payload:{msg:error}});
    }
  }


export function* loadAssinatura({payload}) {
  const email = payload.email;
  if(email!=''){
    let dt1, dt2, assinatura
    assinatura=false
    let diasAcesso=0;
  
    try{
      //Retorna sessão atual de último acesso
      const snapshot = yield call(rsf.firestore.getDocument, 'log/'+email);
      const log = snapshot.data();
      dt1 = log.currentAccess.toDate()

      const collectionRef = db
        .collection("assinaturas/")
        .where("email","==",email)

        const snapshotsAssinatura = yield call(rsf.firestore.getCollection, collectionRef);
        
        snapshotsAssinatura.forEach(dt => {
          dt2 = new Date(dt.data().validade)
          if(!assinatura){
            if(dt1.getTime()<=dt2.getTime()){
              assinatura=true
              diasAcesso=Math.ceil((dt2.getTime()-dt1.getTime())/(1000 * 60 * 60 * 24))
            }
          }
        });

        //Verifica assinatura no hotmart
        if(!assinatura){
          const dt = yield call(axios.post,process.env.REACT_APP_WEBSERVICE+"hotmart/checkAssinatura",{
            email:payload.email,
          })
          let data = dt.data;
          let dtA={};

          if(data.hasOwnProperty('data')){
            if(data.data.page_info.total_results>0){
              data.data.items.map((values,key)=>{
                if(values['status']=='ACTIVE'){
                  assinatura=true;
                }
              })
            }
          }

        }
        let mesAno = `${dt1.getMonth()+1}`.padStart(2, "0")+"-"+dt1.getFullYear();
        if(!assinatura){
          //Aqui faz a busca por cursos individuais
          yield put({ type: actions.LOAD_PRODUTOS,payload:{email:payload.email}});
          // yield call(loadProdutosBd,{payload:{email:payload.email}});
        }
        yield put({ type: actions.LOAD_ASSINATURA_SUCCESS,payload:{aluno:assinatura, currentAccess:dt1, mesAno:mesAno, diasAcesso:diasAcesso}});
    }catch(error){
      console.log(error)
      yield put({ type: actions.LOAD_ERROR, payload:{msg:error}});
    }
  }
}

export function* loadProdutosBd({payload}) {
    const email = payload.email;
    let dt1, dt2
    let diasAcesso=0;
    try{
      const snapshot = yield call(rsf.firestore.getDocument, 'log/'+email);
      const log = snapshot.data();
      dt1 = log.currentAccess.toDate()
        //Verifica novas compras e salva no bd - via hotmart
        const dt = yield call(axios.post,process.env.REACT_APP_WEBSERVICE+"hotmart/consultaVendaEmail",{
          email:payload.email,
        })

        // if(dt.data){
          let cursosContratados={};
          let cursosAcontratar={};
          let cursos={};
          let data;

          const snapshotsCursos = yield call(rsf.firestore.getCollection, 'cursos/');
          
          snapshotsCursos.forEach(c => {
            data = c.data();
            if(data.published){
              if(data.hasOwnProperty('id_hotmart')){
                if(data.id_hotmart){
                  cursos[data.id_hotmart]=data;
                  cursos[data.id_hotmart]['uid']=c.id;
                }else{
                  cursos[c.id]=data;
                  cursos[c.id]['uid']=c.id;
                }
                
              }else{
                cursos[c.id]=data;
                cursos[c.id]['uid']=c.id;
              }
             
            }
          })

          cursosAcontratar=cursos;

          //Puxa as vendas do Banco de dados
          const snapshotsProdutos = yield call(rsf.firestore.getCollection, 'hotmart_transactions/vendas/'+email+'/');
    
          let purchase;
          let product;
          let productsApproved={};
          let alunoAvulso=false;
          let daysContract;
       
          
          //faz o tratamento para vinculação
          snapshotsProdutos.forEach(d => {
            data = d.data();
            purchase = data.purchase;
  
            if(purchase.status==='APPROVED'){
              
              product = data.product;
              dt2 = new Date(purchase.approved_date);

              daysContract = Math.ceil((dt1.getTime()-dt2.getTime())/(1000 * 60 * 60 * 24));
              productsApproved[d.id]={
                id:product.id,
                course:product.name,
                daysApproved:daysContract,
                dateApproved:dt2
              }

              if(cursos[data['product']['id']]){
                if(productsApproved[d.id].daysApproved<=cursos[data['product']['id']].dias_acesso){
                  alunoAvulso=true;
                  cursosContratados[cursos[data['product']['id']].uid]=
                  {
                    ...cursos[data['product']['id']], 
                    isPlataform:true, 
                    id:cursos[data['product']['id']].uid,
                    idHotmart:data['product']['id']
                  };
                }
              }else{
                //Lançar erro na plataforma para identificar que comprou um curso e não tem na plataforma
                cursosContratados[d.id]={
                  curso: productsApproved[d.id].course,
                  id: d.id,
                  key:d.id,
                  idHotmart: data['product']['id'],
                  isPlataform:false
                };
              }
            }
          });
          yield put({ type: actions.LOAD_PRODUTOS_SUCCESS, payload: {purchase:productsApproved, cursosAcontratar: cursosAcontratar, cursosContratados:cursosContratados, alunoAvulso}});
    }catch(error){
      console.log(error);
      yield put({ type: actions.LOAD_ERROR, payload:{msg:error}});
    }
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}


export function* emailVerificationSaga(actionCodeSettings) {
  try {
    yield call(rsf.auth.sendEmailVerification);
    console.log('e-mail enviado com sucesso!')
  }
  catch(error) {
    console.log('erro ao tentar enviar e-mail',error)
  }
}

export function* emailReset(email, actionCodeSettings) {
  try {
    yield call(rsf.auth.sendPasswordResetEmail, email.email);
    yield put({type:actions.EMAIL_RESET_OK})
  }
  catch(error) {
    yield put({type:actions.EMAIL_RESET_ER})
    console.log(error)
    
  }
}

function* updateProfileSaga(name,photo) {
  try {
    yield call(rsf.auth.updateProfile, {
      displayName: name,
      photoURL: photo
    });
  }
  catch(error) {
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case 'delete':
        yield call(rsf.firestore.setDocument, COLLECTION_NAME+"/"+data.email, data, { merge: true });
        break;
      case 'update':
        yield call(
          rsf.firestore.setDocument,
          COLLECTION_NAME+"/"+data.email+"/"+data.credencial,
          {
            ...omit(data, ['email']),
          }
        );
        break;
      default:
        yield call(rsf.firestore.setDocument, COLLECTION_NAME+"/"+data.email, data, { merge: true });
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoFireStoreError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD,loadCredencial),
    takeEvery(actions.LOAD_ASSINATURA,loadAssinatura),
    takeEvery(actions.LOAD_PRODUTOS,loadProdutos),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
  ]);
}
