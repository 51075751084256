'use strict'

module.exports = class Money{
    constructor(value,type='sis'){
        if(type=='sis'){
            this.valor=value;
        }else{
            this.valorUser = value;
        }
    }


    set valor(value) {
        this.value = value;
    }

    set valorUser(value) {
        this.valueUser = value;
    }

    get valor() {
        this.value=this.translateUserToValue(this.valueUser)
        return this.value;
    }

    get valorUser() {
        this.valueUser = this.translateValueToUser(this.value)
        return this.valueUser;
    }

    translateUserToValue(valorUser){
        return this.val(valorUser)
    }

    translateValueToUser(valor){
        return this.number_format(this.val(valor), 2, ',','.')
    }

    val(campo){
		let valor=0;
		if(campo != '0,00' && campo != '' && campo){
            let soma = this.replaceAll(campo,'.','');
            soma =  this.replaceAll( soma,',','.');
            valor = parseFloat(soma); 
        }
        return valor
    }
		

    number_format (number, decimals, dec_point, thousands_sep) {
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    replaceAll(str, de, para){
        if(de && typeof(str)==='string'){
            console.log(de, str)
            let pos = str.indexOf(de);
            while (pos > -1){
                str = str.replace(de, para);
                pos = str.indexOf(de);
            }
            return (str);
        }else{
            return str
        }

        
    }

}