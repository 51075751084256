const DOCUMENT = 'CHECKOUT_';

const actions = {
  STEP_VALID: DOCUMENT + 'STEP_VALID',

  CONCLUIR_PEDIDO: DOCUMENT + 'CONCLUIR_PEDIDO',
  CONCLUIR_PEDIDO_SUCCESS: DOCUMENT + 'CONCLUIR_PEDIDO_SUCCESS',
  CONCLUIR_PEDIDO_ERROR: DOCUMENT + 'CONCLUIR_PEDIDO_ERROR',

  PEDIDO_ABERTO: DOCUMENT + 'PEDIDO_ABERTO',
  PEDIDO_ABERTO_SUCCESS: DOCUMENT + 'PEDIDO_ABERTO_SUCCESS',
  PEDIDO_ABERTO_ERROR: DOCUMENT + 'PEDIDO_ABERTO_ERROR',

  CONSULTA_PAGAMENTO: DOCUMENT + 'CONSULTA_PAGAMENTO',
  CONSULTA_PAGAMENTO_SUCCESS: DOCUMENT + 'CONSULTA_PAGAMENTO_SUCCESS',
  CONSULTA_PAGAMENTO_ERROR: DOCUMENT + 'CONSULTA_PAGAMENTO_ERROR',

  CONSULTA_PAGAMENTOS: DOCUMENT + 'CONSULTA_PAGAMENTOS',
  CONSULTA_PAGAMENTOS_SUCCESS: DOCUMENT + 'CONSULTA_PAGAMENTOS_SUCCESS',
  CONSULTA_PAGAMENTOS_ERROR: DOCUMENT + 'CONSULTA_PAGAMENTOS_ERROR',
 
  stepValid: (step, valid) => ({
    type: actions.STEP_VALID,
    payload: { step, valid },
  }),

  pedidoAberto: (email) => ({
    type: actions.PEDIDO_ABERTO,
    payload: { email },
  }),

  concluirPedido: (email, descricaoFaturamento, total, produtosArray=[], desconto=0, indicacao='') => ({
    type: actions.CONCLUIR_PEDIDO,
    payload: { 
      email, 
      descricaoFaturamento,
      total,
      produtosArray,
      desconto,
      indicacao
    },
  }),

  consultaPagamento: (id,email) => ({
    type: actions.CONSULTA_PAGAMENTO,
    payload: { 
     id,
     email
    },
  }),

  consultaPagamentos: (email) => ({
    type: actions.CONSULTA_PAGAMENTOS,
    payload: { 
     email
    },
  }),


};
export default actions;
