import React, {lazy, Suspense}  from 'react';
import { Route, Redirect, Router,Switch, Link } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import {useSelector, useDispatch} from 'react-redux';
import Loader from './components/utility/loader';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

  const RestrictedRoute= ({ component: Component, ...rest }) => {

    const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
      return(
      <Route
        {...rest}
        render={props =>
          isLoggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname:"/islogged",
                pg: window.location.pathname
            }}
            />
          )
        }
      />)}

/**
 * 
 * Rotas públicas, verifica depois do .com.br
 */

  
const PublicRoutes = ({ history }) => {


  return (
    <React.Fragment> 
      <Suspense fallback={<Loader />}>
        <ConnectedRouter history={history}>
          <Router history={history}>
            <Route
              exact
              path={'/'}
              component={lazy(()=>import('./containers/App/App'))}
            />

            <Route
              exact
              path={'/instrutores'}
              component={lazy(()=>import('./pages/instructor/Instructors'))}
            />

            <Route
              exact
              path={'/cursos'}
              component={lazy(()=>import('./pages/courses/CourseGrid'))}
            /> 

            <Route
              exact
              path={'/curso/:idCurso'}
              component={lazy(()=>import('./pages/courses/CursoDetalhe'))}
            /> 

            <Route
              exact
              path={'/termos'}
              component={lazy(()=>import('./pages/termos/Termos'))}
            /> 

            <Route
              exact
              path={'/termos_e_servicos'}
              component={lazy(()=>import('./pages/termos/Termos'))}
            /> 

            <Route
              exact
              path={'/contato'}
              component={lazy(()=>import('./pages/contact/Contact'))}
            /> 


            <Route
              exact
              path={'/logout'}
              component={lazy(()=>import('./pages/account/Logout'))}
            />

            <Route
              exact
              path={'/islogged'}
              component={lazy(()=>import('./pages/account/IsLogged'))}
            />

            <Route
              exact
              path={'/contratar/:idCurso'}
              component={lazy(()=>import('./CheckoutProduto'))}
            />

            <Route
              exact
              path={'/contratar'}
              component={lazy(()=>import('./Checkout'))}
            />

            

            <Route
              exact
              path={'/registro'}
              component={lazy(()=>import('./pages/account/Register'))}
            />

            <Route
              exact
              path={'/register'}
              component={lazy(()=>import('./pages/account/Register'))}
            />

            <Route
              exact
              path={'/registration'}
              component={lazy(()=>import('./pages/account/Register'))}
            />

            <Route
              exact
              path={'/planos'}
              component={lazy(()=>import('./pages/Planos'))}
            />

            <Route
              exact
              path={'/login'}
              component={lazy(()=>import('./pages/account/Login'))}
            />

            <Route
              exact
              path={'/forgotpassword'}
              component={lazy(()=> import('./pages/account/ForgotPassword'))}
            />



            <Route
              exact
              path={'/cursosonline'}
              component={lazy(()=>import('./pages/cursosOnLine/CourseGrid'))}
            /> 

            <Route
              exact
              path={'/cursosonline/:idCurso'}
              component={lazy(()=>import('./pages/cursosOnLine/CursoDetalhe'))}
            /> 

            <Route
              exact
              path={'/cursosonline/:idCurso/:slug'}
              component={lazy(()=>import('./pages/cursosOnLine/CursoDetalhe'))}
            /> 

          <RestrictedRoute
            path="/dashboard"
            component={Dashboard}
          />  
            <RestrictedRoute
              path="/logged"
              component={Dashboard}
            /> 
          </Router>
        </ConnectedRouter>
        </Suspense>
    </React.Fragment>
  );
};
export default PublicRoutes;