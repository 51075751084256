import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/mobileMenu.js";
    
function Menu() {
    return (
    <ul className="nav menu-nav">
        <li className="nav-item dropdown active">
            <Link className="nav-link" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Home <i className="las"></i></Link>
            {/* <ul className="dropdown list-unstyled">
                <li className="nav-item active"><Link className="nav-link" to={process.env.PUBLIC_URL + "/"}>Home Style 1</Link></li>
                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/home-two"}>Home Style 2</Link></li>
            </ul> */}
        </li>
        {/* <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Pages <i className="las la-angle-down"></i></Link>
            <ul className="dropdown list-unstyled">
                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/about"}>About Us</Link></li>
                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/gallery"}>Gallery</Link></li>
                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/login"}>Log In</Link></li>
                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/registration"}>Registration</Link></li>
                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/faq"}>Faq</Link></li>
                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/404"}>404</Link></li>
                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/coming-soon"}>Coming Soon</Link></li>
            </ul>
        </li> */}
        <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/cursos"} data-toggle="dropdown">Cursos <i className="las"></i></Link>
            {/* <ul className="dropdown list-unstyled">
                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-grid"}>Ao Vivo</Link></li>
                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-list"}>Gravados</Link></li>
            </ul> */}
        </li>
        <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/instrutores"} data-toggle="dropdown">Instrutores <i className="las"></i></Link>
            {/* <ul className="dropdown list-unstyled">
                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/instructor"}>Instructors</Link></li>
                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/instructor-details"}>Instructor Details</Link></li>
            </ul> */}
        </li>
        {/* <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/eventos"} data-toggle="dropdown">Eventos <i className="las"></i></Link>
        </li> */}
        {/* <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Blog<i className="las"></i></Link>
             <ul className="dropdown list-unstyled">
                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/blog-classic"}>Blog Classic</Link></li>
                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/blog-grid"}>Blog Grid</Link></li>
                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/blog-details"}>Blog Details</Link></li>
            </ul> 
        </li> */}
        <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/planos"} data-toggle="dropdown">Planos <i className="las"></i></Link>
        </li>
        <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/contratar"} data-toggle="dropdown">Contratar <i className="las"></i></Link>
        </li>

    </ul>
    )
}export default Menu