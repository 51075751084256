export default {
  apiUrl: 'https://cqc.coopercont.com.br/'
};
const siteConfig = {
  siteName: 'CQC - Clube de Qualificação Coopercont',
  siteNameMini: 'CQC',
  siteIcon: 'ion-flash',
  linkIcon: './image/favicon.png',
  footerText: 'CQC - Coopercont ©2021 Desenvolvido por NFJ Sistemas - V1.01.07',
  linkFooterText: 'https://www.nfj.com.br',
  addressFull: 'Rua Lysimaco Ferreira da Costa, 638, Curitiba – PR.',
  city: 'Curitiba – PR.',
  fone: '(41) 99171-6714',
  email: 'contato@coopercont.com.br',
  logo_171_41:'/assets/images/logo_171_41_blank.png',
  logo_coopercont:'/assets/images/logo_coopercont.png',
  sobre_nos:'',
  linkedin:'https://www.linkedin.com/company/coopercont/',
  facebook:'https://www.facebook.com/coopercont',
  instagram: 'https://www.instagram.com/coopercont.oficial/'
};
const firebaseConfig = {
    apiKey: "AIzaSyBF2zO0X9ZIB6LLR0K5IguX8belJG7Q8p4",
    authDomain: "cqccoopercont-30267.firebaseapp.com",
    projectId: "cqccoopercont-30267",
    storageBucket: "cqccoopercont-30267.appspot.com",
    messagingSenderId: "1066346344773",
    appId: "1:1066346344773:web:550b3cf1718f9b5f17ce2c",
    measurementId: "G-5ZEP2LDW8F"
};

export{
  siteConfig,
  firebaseConfig
}


