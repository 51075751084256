const DOCUMENT = 'HOTMART_';

const actions = {
  CHECK_ASSINATURA: DOCUMENT + 'CHECK_ASSINATURA',
  CHECK_ASSINATURA_SUCCESS: DOCUMENT + 'CHECK_ASSINATURA_SUCCESS',
  CHECK_ASSINATURA_ERROR: DOCUMENT + 'CHECK_ASSINATURA_ERROR',

  CHECK_PRODUTO: DOCUMENT + 'CHECK_PRODUTO',
  CHECK_PRODUTO_SUCCESS: DOCUMENT + 'CHECK_PRODUTO_SUCCESS',
  CHECK_PRODUTO_ERROR: DOCUMENT + 'CHECK_PRODUTO_ERROR',

  CHECK: DOCUMENT + 'CHECK',
  CHECK_SUCCESS: DOCUMENT + 'CHECK_SUCCESS',
  CHECK_ERROR: DOCUMENT + 'CHECK_ERROR',

  CHECK_PRODUTOS: DOCUMENT + 'CHECK_PRODUTOS',
  CHECK_PRODUTOS_SUCCESS: DOCUMENT + 'CHECK_PRODUTOS_SUCCESS',
  CHECK_PRODUTOS_ERROR: DOCUMENT + 'CHECK_PRODUTOS_ERROR',

  LANCA_PEDIDO: DOCUMENT + 'LANCA_PEDIDO',
  LANCA_PEDIDO_SUCCESS: DOCUMENT + 'LANCA_PEDIDO_SUCCESS',
  LANCA_PEDIDO_ERROR: DOCUMENT + 'LANCA_PEDIDO_ERROR',

  CONCLUIR_PEDIDO: DOCUMENT + 'CONCLUIR_PEDIDO',
  CONCLUIR_PEDIDO_SUCCESS: DOCUMENT + 'CONCLUIR_PEDIDO_SUCCESS',
  CONCLUIR_PEDIDO_ERROR: DOCUMENT + 'CONCLUIR_PEDIDO_ERROR',

  PEDIDO_ABERTO: DOCUMENT + 'PEDIDO_ABERTO',
  PEDIDO_ABERTO_SUCCESS: DOCUMENT + 'PEDIDO_ABERTO_SUCCESS',
  PEDIDO_ABERTO_ERROR: DOCUMENT + 'PEDIDO_ABERTO_ERROR',

  CONSULTA_PAGAMENTO: DOCUMENT + 'CONSULTA_PAGAMENTO',
  CONSULTA_PAGAMENTO_SUCCESS: DOCUMENT + 'CONSULTA_PAGAMENTO_SUCCESS',
  CONSULTA_PAGAMENTO_ERROR: DOCUMENT + 'CONSULTA_PAGAMENTO_ERROR',

  RESET: DOCUMENT + 'RESET',
 
  stepValid: (step, valid) => ({
    type: actions.STEP_VALID,
    payload: { step, valid },
  }),

  pedidoAberto: (email) => ({
    type: actions.PEDIDO_ABERTO,
    payload: { email },
  }),

  concluirPedido: (email, descricaoFaturamento, total, produtosArray=[], desconto=0, indicacao='') => ({
    type: actions.CONCLUIR_PEDIDO,
    payload: { 
      email, 
      descricaoFaturamento,
      total,
      produtosArray,
      desconto,
      indicacao
    },
  }),

  consultaPagamento: (id,email) => ({
    type: actions.CONSULTA_PAGAMENTO,
    payload: { 
     id,
     email
    },
  }),

  consultaPagamentos: (email) => ({
    type: actions.CONSULTA_PAGAMENTOS,
    payload: { 
     email
    },
  }),

  checkAssinatura: (email) => ({
    type: actions.CHECK_ASSINATURA,
    payload: { 
     email
    },
  }),

  checkProduto: (email,idHotmart) => ({
    type: actions.CHECK_PRODUTO,
    payload: { 
     email,
     idHotmart
    },
  }),

  checkProdutos: (email) => ({
    type: actions.CHECK_PRODUTOS,
    payload: { 
     email
    },
  }),

  check: (email) => ({
    type: actions.CHECK,
    payload: { 
     email
    },
  }),

  lancaPedido: (email,idCurso,tipo) => ({
    type: actions.LANCA_PEDIDO,
    payload: { 
     email,
     idCurso,
     tipo
    },
  }),


};
export default actions;
