import { all, takeEvery, put, call} from 'redux-saga/effects';
import { eventChannel } from "redux-saga";
import actions from './actions';
import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';
import axios from 'axios'
import fileDownload from 'js-file-download'
import firebase from 'firebase'

const COLLECTION_NAME = 'curso_assinatura';

function* load({ payload }) {
  try {
    const ORDER_BY = 'numero_modulo';
    const ORDER = 'asc';

    const collectionRef = db
      .collection(COLLECTION_NAME+"/"+payload.emailProfessor+"/cursos/"+payload.idCurso+"/modulos")
      .orderBy(ORDER_BY, ORDER)

      const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    let data;
    snapshots.forEach(user => {
        data = {
          ...data,
          [user.id]: user.data()
        }
    });
    let curso={};
    if(data){
      curso[payload.idCurso]=data;  
    }

    const inscrito = yield call(rsf.firestore.getDocument, "curso_inscricao/inscricao"+"/"+payload.emailAluno+"/"+payload.idCurso);
    let dtInscrito = inscrito.data();
    let isInscrito = false;
    if(dtInscrito){
      if(dtInscrito.inscrito){
        isInscrito = true;
      }
    }
    let aInscrito={}
    aInscrito [payload.idCurso]=isInscrito;
    yield put(actions.loadSuccess(curso,aInscrito));
  } catch (error) {
    console.log(error)
    yield put(actions.loadError(error));
  }
}

function* inscrever({payload}){
  try{
    yield call(rsf.firestore.setDocument, `curso_inscricao/inscricao/${payload.emailAluno}/${payload.idCurso}`, {
        dtInscricao: firebase.firestore.FieldValue.serverTimestamp(),
        inscrito:true
    },{merge:true});
    yield call(rsf.firestore.addDocument, `log_cursos/inscrito/${payload.idCurso}`, {
      dtInscrito: firebase.firestore.FieldValue.serverTimestamp(),
      email:payload.emailAluno,
      op:'inscrito'
  },{merge:true});
      yield put({type:actions.INSCREVER_SUCCESS,payload:{idCurso:payload.idCurso}});
  }catch(error){
    console.log(error)
  }
}

function* wasLoad({payload}){
  try{
    let was_load = payload.was_load;

    if(!was_load){
      yield put({type:actions.LOAD,payload:{idCurso:payload.idCurso}});
    }
  }catch(error){
    console.log(error)
  }
}

function* loadAnexos({ payload }) {
  try {
    const snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME+"/"+payload.emailProfessor+"/cursos/"+payload.idCurso+"/arquivos/");
    let data;
    let anexos={};
    snapshots.forEach(dt => {
        data = {
          ...data,
          [dt.id]: dt.data()
        }
    });
    anexos[payload.key]=data;
    yield put(actions.loadAnexosSuccess(anexos));
  } catch (error) {
    console.log(error)
    yield put(actions.loadAnexosError(error));
  }
}

function* downloadFile(data) {
  try{
    const url = yield call(rsf.storage.getDownloadURL, data.payload.caminho);
    
    yield call(handleDownload, url,data.payload.fileName);
  }catch(erro){
    console.log(erro)
  }
  
}

function* handleDownload(url, filename){
  axios.get(url, {
    responseType: 'blob',
  })
  .then((res) => {
    fileDownload(res.data, filename)
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD, load),
    takeEvery(actions.DOWNLOAD, downloadFile),
    takeEvery(actions.LOAD_ANEXOS, loadAnexos),
    takeEvery(actions.INSCREVER, inscrever),
    takeEvery(actions.WAS_LOAD, wasLoad),
  ]);
}
