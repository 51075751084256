const Money = require('./class/Money');

const getValue = (valueUser)=>{
    let value = new Money;
    value.setValorUser = valueUser;
    return value.valor;
}

const getValueUser = (value)=>{
    let valueUser = new Money;
    valueUser.valor = value;
    return valueUser.valorUser;
}



module.exports = {
    getValue,
    getValueUser
}



