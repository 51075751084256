import { all, takeEvery, put, call} from 'redux-saga/effects';
import { eventChannel } from "redux-saga";
import actions from './actions';
import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';

const COLLECTION_NAME = 'cad_cursos'; // change your collection

function* loadFromFirestore({ payload }) {
  try {
    console.log(payload)
    const snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME+"/"+payload.email+"/cursos");
    let data;
    snapshots.forEach(user => {
        data = {
          ...data,
          [user.id]: user.data()
        }
    });
    // let data = yield call(convertCollectionsSnapshotToMap, snapshots);
    
    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error)
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName } = payload;
  const fdata = {...omit(data, ['file'])}
  try {
    // yield call(uploadFile,'private/curso/'+data.email,data.file)
    switch (actionName) {
      case 'delete':
        yield call(
          rsf.firestore.deleteDocument,
          `${COLLECTION_NAME}/${data.key}`);
        break;
      case 'update':
        yield call(
          rsf.firestore.setDocument,
          `${COLLECTION_NAME}/${payload.email}/cursos/${data.key}`,
          {
            ...omit(fdata, ['key']),
          }
        );
        break;
      default:
        yield call(rsf.firestore.addDocument, `${COLLECTION_NAME}/${payload.email}/cursos`, fdata);
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE, payload:{email:payload.email}});
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoFireStoreError(error));
  }
}

function* uploadFile(path, file) {
  const task = rsf.storage.uploadFile(path, file);
  console.log(task,path);
  const channel = eventChannel(emit => task.on('state_changed', emit));
  // yield takeEvery(channel, );
  // Wait for upload to complete
  yield task
  // Do something on complete
}

// function* resetFireStoreDocuments() {
//   try {
//     yield call(deleteDocuments, COLLECTION_NAME);
//     yield call(addCollectionAndDocuments, COLLECTION_NAME, fakeDataList);
//     yield put({ type: actions.LOAD_FROM_FIRESTORE });
//   } catch (error) {
//     console.log(error);
//   }
// }

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
    // takeEvery(actions.RESET_FIRESTORE_DOCUMENTS, resetFireStoreDocuments),
  ]);
}
