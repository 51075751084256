import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  cursos:{},
  inscrito:{}
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cursos:{...state.cursos,...payload.data},
        inscrito:{...state.inscrito,...payload.inscrito},
        errorMessage: false,
      };
    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Houve um problema no carregamento',
      };
      case actions.LOAD_ANEXOS:
        return {
          ...state,
          isLoading: true,
          errorMessage: false,
        };
      case actions.LOAD_ANEXOS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          anexos: payload.data,
          errorMessage: false,
        };
    case actions.INSCREVER_SUCCESS:
      return {
        ...state,
        inscrito:{...state.inscrito, [payload.idCurso]:true}
      };

      case actions.LOAD_INSCRITO:
        return {
          ...state,
          inscrito:{...state.inscrito, [payload.idCurso]:true}
        };
      
      
      case actions.LOAD_ANEXOS_ERROR:
        return {
          ...state,
          isLoading: false,
          errorMessage: 'Houve um problema no carregamento',
        };  
    default:
      return state;
  }
}
