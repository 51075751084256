import actions from "./actions";
const initState = { 
  load:false, 
  gestor: false, 
  instituicao:false, 
  instrutor:false, 
  aluno:false,
  alunoAvulso:false, 
  loadErro:false, 
  loadSuccess:false, 
  currentAccess:Date.now(),
  mesAno:'',
  diasAcesso:0,
  loadedAssinatura:false,
  loadedProdutos:false,
  loadedCredenciais:false,
  purchase:{},
  cursosContratados:{},
  cursosAcontratar:{},
  msg:""};

export default function credencialReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOAD_SUCCESS:
      if(!action.payload){
        action.payload = initState;
      }
      return {
        ...state,
        load: true,
        gestor: action.payload.gestor,
        instituicao: action.payload.instituicao,
        instrutor: action.payload.instrutor,
        loadedCredenciais:true,
        loadSuccess: true,
      };
   case actions.RESET:
      return initState;

  case actions.LOAD_ASSINATURA_SUCCESS:
    return {
      ...state,
      aluno: action.payload.aluno,
      currentAccess:action.payload.currentAccess,
      mesAno:action.payload.mesAno,
      diasAcesso:action.payload.diasAcesso,
      loadedAssinatura:true,
      loadSuccess: true,
    };  
    case actions.LOAD_PRODUTOS_SUCCESS:
      return {
        ...state,
        purchase: action.payload.purchase,
        cursosContratados:action.payload.cursosContratados,
        cursosAcontratar:action.payload.cursosAcontratar,
        alunoAvulson:action.payload.alunoAvulso,
        loadedProdutos:true,
        loadSuccess: true,
      };    
   case actions.LOAD_ERROR:
        return {...state, 
          loadErro:true,
          msg:action.payload.msg
        };  
    case actions.RESET:
      return {...initState};     
    default:
      return {...state};
  }
}
