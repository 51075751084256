import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  eventos: [],
  cursos: [],
  categorias: [],
  perguntas_respostas: [],
  erroMessage:false,
  produtos:[],
  cooperquintas:[],
  wasLoading:false,
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: false,
        wasLoading:true,
        eventos:payload.data['eventos'],
        perguntas_respostas:payload.data['perguntas_respostas'],
        instrutores:payload.data['instrutores'],
        cursos:payload.data['cursos'],
        produtos:payload.data['produtos'],
        cooperquintas:payload?.data['cooperquintas'],
        categorias:payload.data['categorias'],
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        wasLoading:false,
        errorMessage: 'Houve um problema no carregamento',
      };
    default:
      return state;
  }
}
