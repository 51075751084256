import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import omit from 'lodash/omit';
import { rsf} from '@iso/lib/firebase/firebase';


function* load() {
  try {
   
  } catch (error) {
    
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD,load),
  ]);
}
