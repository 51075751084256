const DOCUMENT = 'CAD_CURSOS_MODULOS_';

const actions = {
  LOAD_FROM_FIRESTORE: DOCUMENT + 'LOAD_FROM_FIRESTORE',
  LOAD_FROM_FIRESTORE_SUCCESS: DOCUMENT + 'LOAD_FROM_FIRESTORE_SUCCESS',
  LOAD_FROM_FIRESTORE_ERROR: DOCUMENT + 'LOAD_FROM_FIRESTORE_ERROR',

  SAVE_INTO_FIRESTORE: DOCUMENT + 'SAVE_INTO_FIRESTORE',
  SAVE_INTO_FIRESTORE_ERROR: DOCUMENT + 'SAVE_INTO_FIRESTORE_ERROR',

  RESET_FIRESTORE_DOCUMENTS: DOCUMENT + 'RESET_FIRESTORE_DOCUMENTS',
  RESET_FIRESTORE_DOCUMENTS_ERROR: DOCUMENT + 'RESET_FIRESTORE_DOCUMENTS_ERROR',

  TOGGLE_FIRESTORE_HANDLE_MODAL: DOCUMENT + 'TOGGLE_FIRESTORE_HANDLE_MODAL',
  FIRESTORE_UPDATE: DOCUMENT + 'FIRESTORE_UPDATE',
  FIRESTORE_UPDATE_EDITOR: DOCUMENT + 'FIRESTORE_UPDATE_EDITOR',

  DOWNLOAD: DOCUMENT + 'DOWNLOAD',

  LOAD_ANEXOS: DOCUMENT + 'LOAD_ANEXOS',
  LOAD_ANEXOS_SUCCESS: DOCUMENT + 'LOAD_ANEXOS_SUCCESS',
  LOAD_ANEXOS_ERROR: DOCUMENT + 'LOAD_ANEXOS_ERROR',

  DELETE_ANEXO: DOCUMENT + 'DELETE_ANEXO',

  loadFromFireStore: (email,key) => {
    return { type: actions.LOAD_FROM_FIRESTORE,
          payload: {email, key}};
  },

  loadFromFireStoreSuccess: data => ({
    type: actions.LOAD_FROM_FIRESTORE_SUCCESS,
    payload: { data },
  }),

  loadFromFireStoreError: error => ({
    type: actions.LOAD_FROM_FIRESTORE_ERROR,
    payload: { error },
  }),

  saveIntoFireStore: (data, actionName = 'insert', email, idCurso) => ({
    type: actions.SAVE_INTO_FIRESTORE,
    payload: { data, actionName, "email":email, "idCurso":idCurso },
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_FIRESTORE_HANDLE_MODAL,
    payload: { data },
  }),

  loadAnexos: (email, key, keyModulo) => {
    return { type: actions.LOAD_ANEXOS,
          payload: {email, key, keyModulo}};
  },

  deleteAnexo: (path, keyAnexo) => {
    return { type: actions.DELETE_ANEXO,
          payload: {path, keyAnexo}};
  },

  loadAnexosSuccess: data => ({
    type: actions.LOAD_ANEXOS_SUCCESS,
    payload: { data },
  }),

  loadAnexosError: error => ({
    type: actions.LOAD_ANEXOS_ERROR,
    payload: { error },
  }),

  downloadFile: (data, fileName) => ({
    type: actions.DOWNLOAD,
    payload: { 
      "caminho": data,
      "fileName":fileName 
    },
  }),


  update: data => ({
    type: actions.FIRESTORE_UPDATE,
    payload: { data },
  }),

  updateEditor: (data, campo) => ({
    type: actions.FIRESTORE_UPDATE_EDITOR,
    payload: { campo: data },
  }),


  saveIntoFireStoreError: error => ({
    type: actions.SAVE_INTO_FIRESTORE_ERROR,
    payload: { error },
  }),

  resetFireStoreDocuments: () => ({
    type: actions.RESET_FIRESTORE_DOCUMENTS,
  }),

  resetFireStoreDocumentsError: error => ({
    type: actions.RESET_FIRESTORE_DOCUMENTS_ERROR,
    payload: { error },
  }),
};
export default actions;
