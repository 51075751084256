import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import aluno from './aluno/sagas';
import assinatura from './assinatura/sagas';
import ecommerce from './ecommerce/sagas';
import perguntasRespostas from './perguntasRespostas/sagas'
import cadInstrutor from './cadInstrutor/sagas';
import cadInstrutorPerfil from './cadInstrutorPerfil/sagas';
import cadPerfil from './cadPerfil/sagas';
import cadInstituicao from './cadInstituicao/sagas';
import cadGestor from './cadGestor/sagas';
import cadCategoria from './cadCategoria/sagas';
import cadCursos from './cadCursos/sagas';
import cadPlano from './cadPlano/sagas';
import cursos from './cursos/sagas';
import cadCursosModulos from './cadCursosModulos/sagas';
import cadCursosModulosAulas from './cadCursosModulosAulas/sagas';
import cadFormularios from './cadFormularios/sagas';
import credencial from './credencial/sagas';
import pageHome from './pageHome/sagas';
import formularios from './formularios/sagas';
import cart from './cart/sagas';
import usuario from './usuario/sagas';
import contact from './contact/sagas';
import checkout from './checkout/sagas';
import hotmart from './hotmart/sagas';
import certificado from './certificado/sagas';
import cadAluno from './cadAluno/sagas';
import cadCooperado from './cadCooperado/sagas';
import cursosOnLine from './cursosOnLine/sagas';
import perguntar from './perguntar/sagas';
import cadCooperquintas from './cadCooperquintas/sagas';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    aluno(),
    assinatura(),
    contact(),
    ecommerce(),
    cursos(),
    perguntasRespostas(),
    cadInstrutor(),
    cadInstrutorPerfil(),
    cadInstituicao(),
    cadGestor(),
    cadCategoria(),
    cadCursos(),
    cadCursosModulos(),
    cadCursosModulosAulas(),
    cadPlano(),
    cadPerfil(),
    cadFormularios(),
    credencial(),
    checkout(),
    pageHome(),
    formularios(),
    cart(),
    usuario(),
    hotmart(),
    certificado(),
    cadAluno(),
    cadCooperado(),
    cursosOnLine(),
    perguntar(),
    cadCooperquintas()
  ]);
}
