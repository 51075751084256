import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  fdatas: {},
  modalActive: false,
  anexos:{},
  fdata: {
    key: '',
    id: new Date().getTime(),
    modulo: '',
    descricao:'',
    link_video:'',
    apostila:'',
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
    file:[]
  },
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fdatas: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Houve um problema no carregamento',
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      if(payload.data === null){
        return {
          ...state,
          modalActive: !state.modalActive,
          fdata: {...initState.fdata},
        };
      }else{
        return {
          ...state,
          modalActive: !state.modalActive,
          fdata: payload.data,
        };
      }
      
      case actions.LOAD_ANEXOS:
        return {
          ...state,
          isLoading: true,
          errorMessage: false,
        };
      case actions.LOAD_ANEXOS_SUCCESS:
        let data = payload.data
        return {
          ...state,
          isLoading: false,
          anexos:{...state['anexos'],...data},
          errorMessage: false,
        };
      case actions.LOAD_ANEXOS__ERROR:
        return {
          ...state,
          isLoading: false,
          errorMessage: 'Houve um problema no carregamento',
        };  
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        fdata: payload.data,
      };
    default:
      return state;
  }
}
