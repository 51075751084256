import Auth from './auth/reducer';
import App from './app/reducer';
import Aluno from './aluno/reducers';
import Assinatura from './assinatura/reducers';
import Ecommerce from './ecommerce/reducers';
import ThemeSwitcher from './themeSwitcher/reducer';
import Usuario from './usuario/reducers';
import User from './user/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import PerguntasRespostas from './perguntasRespostas/reducers';
import CadInstrutor from './cadInstrutor/reducers';
import CadInstrutorPerfil from './cadInstrutorPerfil/reducers';
import CadInstituicao from './cadInstituicao/reducers';
import CadGestor from './cadGestor/reducers';
import CadPerfil from './cadPerfil/reducers';
import CadPlano from './cadPlano/reducers';
import Credencial from './credencial/reducers';
import PageHome from './pageHome/reducers';
import CadCategoria from './cadCategoria/reducers';
import CadCursos from './cadCursos/reducers';
import Cursos from './cursos/reducers';
import CadCursosModulos from './cadCursosModulos/reducers';
import CadCursosModulosAulas from './cadCursosModulosAulas/reducers';
import CadFormularios from './cadFormularios/reducers';
import Formularios from './formularios/reducers';
import Cart from './cart/reducers';
import Contact from './contact/reducers';
import Checkout from './checkout/reducers';
import Hotmart from './hotmart/reducers';
import Certificado from './certificado/reducers';
import CadAluno from './cadAluno/reducers';
import CadCooperado from './cadCooperado/reducers';
import CursosOnLine from './cursosOnLine/reducers';
import Perguntar from './perguntar/reducers';
import CadCooperquintas from './cadCooperquintas/reducers';

export default {
  Assinatura,
  Aluno,
  Auth,
  App,
  Contact,
  Cursos,
  Checkout,
  Ecommerce,
  ThemeSwitcher,
  Usuario,
  User,
  LanguageSwitcher,
  PerguntasRespostas,
  CadInstrutor,
  CadInstrutorPerfil,
  CadInstituicao,
  CadGestor,
  CadPlano,
  CadPerfil,
  Credencial,
  PageHome,
  CadCategoria,
  CadCursos,
  CadCursosModulos,
  CadCursosModulosAulas,
  CadFormularios,
  Formularios,
  Cart,
  Hotmart,
  Certificado,
  CadAluno,
  CadCooperado,
  CursosOnLine,
  Perguntar,
  CadCooperquintas
};
