import React, { Component } from 'react';
import Datas from '../data/footer/footer.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BackToTop from './common/BackToTop';
import { Styles } from "./styles/footerOne.js";
import {siteConfig} from '../settings';

class Footer extends Component {
    componentDidMount(){
        (() => {
            if (!localStorage.cqcCookies) {
              document.querySelector(".box-cookies").classList.remove('hide');
            }
            
            const acceptCookies = () => {
              document.querySelector(".box-cookies").classList.add('hide');
              localStorage.setItem("cqcCookies", "accept");
            };
            
            const btnCookies = document.querySelector(".btn-cookies");
            
            btnCookies.addEventListener('click', acceptCookies);
          })();
    }
    render() {
        return (
            <Styles>
                {/* Footer Area */}
                <footer className="footer1" style={{ backgroundImage: `url(assets/images/${process.env.PUBLIC_URL + Datas.backgroundImage})` }}>
                    <Container>
                        <Row>
                            <Col md="5">
                                <div className="footer-logo-info">
                                    <img src={process.env.PUBLIC_URL + siteConfig.logo_171_41} alt="" className="img-fluid" />
                                    {/* <p>Lorem ipsum dolor sit amet, consectet adipisicing elit. Saepe porro neque a nam null quos.  Adipisci eius unde magnam ad, nisi voluptates.</p> */}
                                    <ul className="list-unstyled">
                                        <li><i className="las la-map-marker"></i>{siteConfig.addressFull}</li>
                                        <li><i className="las la-envelope"></i>{siteConfig.email}</li>
                                        <li><i className="las la-phone"></i>{siteConfig.fone}</li>
                                        
                                    </ul>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="f-links">
                                    <h5>Links</h5>
                                    <ul className="list-unstyled">
                                        <li><Link to="/termos"><i className="las la-angle-right"></i>Termos e Condições</Link></li>
                                        <li><Link to="/termos"><i className="las la-angle-right"></i>Políticas de Privacidade</Link></li>
                                        <li><Link to="/termos"><i className="las la-angle-right"></i>LGPD</Link></li>
                                    </ul>
                                    
                                </div>
                            </Col>
                            <Col md="4">
                                {/* <div className="f-post">
                                    <h5>Recent Post</h5>
                                    <div className="post-box d-flex">
                                        <div className="post-img">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/blog-2.jpg"} alt="" />
                                        </div>
                                        <div className="post-content">
                                            <Link to={process.env.PUBLIC_URL +"/blog-details"}>Lorem ipsum dolor sit amet consectet adipisicing elit com...</Link>
                                            <span>Mar 30, 2020</span>
                                        </div>
                                    </div>
                                    <div className="post-box d-flex">
                                        <div className="post-img">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/blog-3.jpg"} alt="" />
                                        </div>
                                        <div className="post-content">
                                            <Link to={process.env.PUBLIC_URL +"/blog-details"}>Lorem ipsum dolor sit amet consectet adipisicing elit com...</Link>
                                            <span>Mar 30, 2020</span>
                                        </div>
                                    </div>
                                </div> */}
                            </Col>
                        </Row>
                    </Container>
                </footer>

                {/* Copyright Area */}
                <section className="copyright-area">
                    <Container>
                        <Row>
                            <Col md="6">
                                <div className="copy-text">
                                    <p>Copyright &copy; 2021 Coopercont | Desenvolvido by <a href="https://www.nfj.com.br" target="_blank" rel="noopener noreferrer">NFJ</a></p>
                                </div>
                            </Col>
                            <Col md="6" className="text-right">
                                <ul className="social list-unstyled list-inline">
                                    <li className="list-inline-item"><a href={siteConfig.facebook}><i className="fab fa-facebook-f"></i></a></li>
                                    {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li> */}
                                    <li className="list-inline-item"><a href={siteConfig.linkedin}><i className="fab fa-linkedin-in"></i></a></li>
                                    <li className="list-inline-item"><a href={siteConfig.instagram}><i className="fab fa-instagram"></i></a></li>
                                    {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li> */}
                                </ul>
                            </Col>
                        </Row>
                    </Container>

                    {/* Back To Top */}
                    <BackToTop/>

                    {/* Aceitar cookies */}
                    <div className="box-cookies hide">
                        <p className="msg-cookies">Este site utiliza de cookies para garantir que você obtenha a melhor experiência. Ao continuiar navegando você aceita os nossos <Link to="termos_e_servicos">termos e serviços</Link>
                        <button className="btn-cookies">Ok, Entendi!</button></p>
                    </div>
                </section>
            </Styles>
        )
    }
}

export default Footer
