import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  buttonContact:'Enviar Contato!',
  buttonNewsletter:'Cadastrar!',
  enbaledButtonContact:true,
  enbaledButtonNewsletter:true,
  fdata:{
    name:'',
    email:'',
    assunto:'',
    mensagem:'',
    emailNewsletter:'',
    nomeNewsletter:'',
  },
  
};

export default function reducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.SAVE_CONTACT:
      return {
        ...state,
        buttonContact: 'Enviando...',
        enbaledButtonContact: false,
      };
    case actions.SAVE_CONTACT_SUCCESS:
      return {
        ...state,
        buttonContact:'Enviar Contato!',
        enbaledButtonContact:true,
        fdata:{
          name:'',
          email:'',
          assunto:'',
          mensagem:''
        }
      };
    case actions.SAVE_CONTACT_ERROR:
      return {
        ...state,
        buttonContact:'Enviar Contato!',
        enbaledButtonContact:true,
      };   
    case actions.SAVE_CONTACT_UPDATE:
      return {
        ...state,
        fdata: payload.data,
      };

      case actions.SAVE_NEWSLETTER:
        return {
          ...state,
          buttonNewsletter: 'Enviando...',
          enbaledButtonNewsletter: false,
        };
      case actions.SAVE_NEWSLETTER_SUCCESS:
        return {
          ...state,
          buttonNewsletter:'Cadastrar!',
          enbaledButtonNewsletter:true,
          fdata:{
            emailNewsletter:'',
            nomeNewsletter:'',
          }
        };
      case actions.SAVE_NEWSLETTER_ERROR:
        return {
          ...state,
          buttonNewsletter:'Cadastrar!',
          enbaledButtonNewsletter:true,
        };   
      case actions.SAVE_NEWSLETTER_UPDATE:
        return {
          ...state,
          fdata: payload.data,
        };  
   
    default:
      return state;
  }
}
