import actions from './actions';
const initState = {
  isLoading: false,
  errorMessage: false,
  cart: {
    q:0,
    products:{}
  },
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
  case actions.ADD:
    let {product} = payload;
    let {idProduct}=payload;
    let q = state.cart.q;
    let cart = state.cart.products;

    if(q>1){
      if(cart.hasOwnProperty(idProduct)){

      }
    }else{
      q+=1;
      cart[idProduct]=product
    }
    return {
      ...state,
      cart:{
        q: q, 
        products: cart},
      errorMessage: false,
      modalActive: false,
    };  
    case actions.ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cart: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Houve um problema ',
      };
    case actions.UPDATE:
      return {
        ...state,
        fdata: payload.data,
      };
    default:
      return state;
  }
}
