import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';

function VerifyLoggedSystem() {
    const isLoggedIn = useSelector(state => state.Auth.isLoggedIn);
    const isRedirect = useSelector(state => state.Auth.isRedirect);
    const routeToRedirect = useSelector(state => state.Auth.routeToRedirect);

    const logged = ()=>{
        if(isLoggedIn){
            if(isRedirect && routeToRedirect!='' && routeToRedirect!='/' ){
                return(<Redirect to={{pathname: routeToRedirect}}/>)
            }else{
                return(<Redirect to={{
                    pathname: "/dashboard"
                  }}/>)
            }
        }else{
            return(<Redirect to={{
                pathname: "/login"
              }}/>)
        }
    }
    return(
        <>
        {logged()}
        </>
    )
}

export default VerifyLoggedSystem