import actions from './actions';

const initState = {
  estatistica:[],
  perguntas:{}
};

export default function reducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    
    case actions.LOAD_ESTATISTICA_SUCCESS:
      return {
        ...state,
        estatistica:payload.dash
      };
      case actions.LOAD_PERGUNTAS_SUCCESS:
        return {
          ...state,
          perguntas:{...payload.perguntas}
        };  
  
    default:
      return state;
  }
}
