import React, { Component } from 'react';
import { Styles } from '../styles/courseSearch.js';
import actionsCursos from '@iso/redux/cursos/actions';
import { useDispatch, useSelector } from 'react-redux';

export default function CourseSearch(){
    const dispatch = useDispatch();
    const filterNome = useSelector(state => state.Cursos.filterNome);

    const onHandleNome = (val)=>{
        dispatch(actionsCursos.filterNome(val))
    }


    return (
        <Styles>
            {/* Course Search */}
            <div className="course-search">
                <h5>Procurar Curso</h5>
                <form action="#">
                    <input type="text" value={filterNome} onChange={event=>onHandleNome(event.target.value)} name="search" placeholder="Nome do curso" />
                    <button disabled><i className="las la-search"></i></button>
                </form>
            </div>
        </Styles>
    )
}
