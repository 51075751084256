import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  cursos:{},
  inscrito:{},
  anexos:{},
  pergunta:{},
  feedback:{},
  aulasTerminadas:{},
  ultimaAulaIniciada:{},
  filter:{},
  filterNome:''
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cursos:{...state.cursos,...payload.data},
        inscrito:{...state.inscrito,...payload.inscrito},
        aLast:{...state.aLast, ...payload.aLast},
        aFinished:{...state.aFinished,...payload.aFinished},
        errorMessage: false,
      };
      case actions.LOAD_MODULO_SUCCESS:
        return {
          ...state,
          isLoading: false,
          cursos:{...state.cursos,...payload.data},
          errorMessage: false,
        }; 
    case actions.FEEDBACK_SUCCESS:
      return {
        ...state,
        feedback: {...state.feedback,[payload.idSend]:true}

      };  
    case actions.FEEDBACK_ERROR:
      return {
        ...state,
        feedback: {...state.feedback,[payload.idSend]:false}
      }; 
    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Houve um problema no carregamento',
      };
      case actions.LOAD_ANEXOS:
        return {
          ...state,
          isLoading: true,
          errorMessage: false,
        };
      case actions.LOAD_ANEXOS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          anexos: payload.data,
          errorMessage: false,
        };
    case actions.INSCREVER_SUCCESS:
      return {
        ...state,
        inscrito:{...state.inscrito, [payload.idCurso]:true}
      };
    case actions.LOAD_INSCRITO_SUCCESS:
      return {
        ...state,
        inscrito:payload.inscrito
      };

      case actions.LOAD_INSCRITO:
        return {
          ...state,
          inscrito:{...state.inscrito, [payload.idCurso]:true}
        };
      case actions.PLAY_INIT_SUCCESS:
        return {
          ...state,
          ultimaAulaIniciada:{...state.ultimaAulaIniciada, [payload.idCurso]:true}
        };  
      case actions.PLAY_FINISHED_SUCCESS:
        return {
          ...state,
          "aFinished":{...state.aFinished,[payload.idCurso]:{...state.aFinished[payload.idCurso],[payload.idModulo]:payload.idModulo}},
          aulasTerminadas:{...state.aulasTerminadas, [payload.idModulo]:true}
        };    
      case actions.LOAD_ANEXOS_ERROR:
        return {
          ...state,
          isLoading: false,
          errorMessage: 'Houve um problema no carregamento',
        };  

        case actions.FILTER_RESET:
            return{...state, 
            filter:{},
            filterNome:''
          };
        case actions.FILTER:
            return{...state, filter:{
              ...state.filter,[payload.campo]:payload.valor
            }
          };  
        case actions.FILTER_NOME:
          return{...state, filterNome:payload.valor}
        
    default:
      return state;
  }
}
