import { all, takeEvery, put, call} from 'redux-saga/effects';
import { eventChannel } from "redux-saga";
import actions from './actions';
import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';
import axios from 'axios'
import fileDownload from 'js-file-download'
import firebase from 'firebase'
import notifications from '@iso/components/Feedback/Notification';

const COLLECTION_NAME = 'cursos_on_line';

function* load({ payload }) {
  try {
    const ORDER_BY = 'numero_modulo';
    const ORDER = 'asc';

    //Busca todos os módulos do curso
    const collectionRef = db
      .collection(COLLECTION_NAME+"/"+payload.emailProfessor+"/cursos/"+payload.idCurso+"/modulos")
      .orderBy(ORDER_BY, ORDER)

      const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
      let data;
      snapshots.forEach(user => {
          data = {
            ...data,
            [user.id]: user.data()
          }
      });
      let curso={};
      if(data){
        curso[payload.idCurso]=data;  
      }

    //Verifica se o aluno está inscrito no curso
    const inscrito = yield call(rsf.firestore.getDocument, "curso_inscricao/inscricao"+"/"+payload.emailAluno+"/"+payload.idCurso);
    let dtInscrito = inscrito.data();
    let isInscrito = false;
    if(dtInscrito){
      if(dtInscrito.inscrito){
        isInscrito = true;
      }
    }
    let aInscrito={}
    aInscrito [payload.idCurso]=isInscrito;

    //Faz a consulta do último vídeo iniciado
    const last = yield call(rsf.firestore.getDocument, "aulas_last/"+payload.emailAluno+"/"+payload.idCurso+"/last");
    let dtLast= last.data();
    
    let aLast={}
    if(dtLast){
      aLast[payload.idCurso]={
        'aula':dtLast.aula,
        'dt':dtLast.dt
      }
    }

    //Busca os cursos terminados
    const finished = yield call(rsf.firestore.getCollection,"aulas_concluidas/"+payload.emailAluno+"/"+payload.idCurso);
      let dtFinished;
      finished.forEach(dtaF => {
        dtFinished = {
            ...dtFinished,
            [dtaF.id]: dtaF.id
          }
      });
      let aFinished={};
      if(dtFinished){
        aFinished[payload.idCurso]=dtFinished;  
      }

    yield put(actions.loadSuccess(curso,aInscrito, aLast,aFinished));

  } catch (error) {
    yield call(notifications.error,{
      message: 'Erro',
      description:
          'Houve um erro!',
    });
    yield put(actions.loadError(error));
  }
}

function* loadCursos(){
try {
  let data={};
  const snapshots = yield call(rsf.firestore.getCollection, 'cursos_on_line');
  snapshots.forEach(doc => {
      data={...data,[doc.id]: {...doc.data(),key:doc.id}}
  }); 
  yield put({type:actions.LOAD_CURSOS_SUCCESS,payload:{data}});
} catch (error) {
  console.log(error);
  yield put({type:actions.LOAD_CURSOS_ERROR,payload:{error}});
}
 
}


//Faz o load somente dos módulos
function* loadModulo({ payload }) {
  try {
    const ORDER_BY = 'numero_modulo';
    const ORDER = 'asc';

    //Busca todos os módulos do curso
    const collectionRef = db
      .collection(COLLECTION_NAME+"/"+payload.emailProfessor+"/cursos/"+payload.idCurso+"/modulos")
      .orderBy(ORDER_BY, ORDER)

      const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
      let data;
      snapshots.forEach(user => {
          data = {
            ...data,
            [user.id]: omit(user.data(),['link_video'])
          }
      });
      let curso={};
      if(data){
        curso[payload.idCurso]=data;  
      }
    yield put(actions.loadModuloSuccess(curso));

  } catch (error) {
    console.log(error)
    yield put(actions.loadError(error));
  }
}

function* inscrever({payload}){
  try{
    yield call(rsf.firestore.setDocument, `curso_inscricao/inscricao/${payload.emailAluno}/${payload.idCurso}`, {
        dtInscricao: firebase.firestore.FieldValue.serverTimestamp(),
        inscrito:true
    },{merge:true});
    yield call(rsf.firestore.addDocument, `log_cursos/inscrito/${payload.idCurso}`, {
      dtInscrito: firebase.firestore.FieldValue.serverTimestamp(),
      email:payload.emailAluno,
      op:'inscrito'
  },{merge:true});
      yield put({type:actions.INSCREVER_SUCCESS,payload:{idCurso:payload.idCurso}});
  }catch(error){
    console.log(error)
  }
}

function* playFinished({payload}){
  try{
    let id;
    if(payload.idAula){
      id = payload.idAula
    }else{
      id = payload.idModulo
    }
    yield call(rsf.firestore.setDocument, `aulas_concluidas/${payload.emailAluno}/${payload.idCurso}/${id}`, {
        dt: firebase.firestore.FieldValue.serverTimestamp(),
        finished:true
    },{merge:true});
    yield put({type:actions.PLAY_FINISHED_SUCCESS,
      payload:{
        idModulo:id,
        "idCurso":payload.idCurso}
      });
  }catch(error){
    console.log(error)
  }
}

function* playInit({payload}){
  try{
    let id;
    if(payload.idAula){
      id = payload.idAula
    }else{
      id = payload.idModulo
    }
    yield call(rsf.firestore.setDocument, `aulas_iniciadas/${payload.emailAluno}/${payload.idCurso}/${id}`, {
      dt: firebase.firestore.FieldValue.serverTimestamp(),
      init:true,
  },{merge:true});
  yield call(rsf.firestore.setDocument, `acompanhamento_cursos/${payload.mesAno}/${payload.emailProfessor}/${payload.emailAluno}`, {
    q:firebase.firestore.FieldValue.increment(1),
    [id]:{
      dt: firebase.firestore.FieldValue.serverTimestamp(),
    }
  },{merge:true});
  yield call(rsf.firestore.setDocument, `aulas_last/${payload.emailAluno}/${payload.idCurso}/last`, {
    dt: firebase.firestore.FieldValue.serverTimestamp(),
    aula:id
},{merge:true});
    yield put({type:actions.PLAY_INIT_SUCCESS,payload:{id:id}});
  }catch(error){
    console.log(error)
  }
}

function* feedback({payload}){
  try{
    yield call(rsf.firestore.addDocument, `curso_feedback/feedback/${payload.idCurso}`, payload);
    yield put({type:actions.FEEDBACK_SUCCESS,
      payload:{
        idSend:payload.idSend}
      });
  }catch(error){
    console.log(error)
  }
}

function* perguntar({payload}){
  try{
    yield call(rsf.firestore.addDocument, `curso_perguntas/${payload.emailProfessor}/${payload.idCurso}`, payload);
    yield put({type:actions.PERGUNTAR_SUCCESS, 
      payload:{
        idSend:payload.idSend}
      });
  }catch(error){
    console.log(error)
  }
}

function* wasLoad({payload}){
  try{
    let was_load = payload.was_load;

    if(!was_load){
      yield put({type:actions.LOAD,payload:{idCurso:payload.idCurso}});
    }
  }catch(error){
    console.log(error)
  }
}

function* loadInscrito({payload}){
  const snapshots = yield call(rsf.firestore.getCollection, "curso_inscricao/inscricao"+"/"+payload.emailAluno);
  let aInscrito={}
  snapshots.forEach(user => {
      aInscrito = {
        ...aInscrito,
        [user.id]: true
      }
  });
  yield put({type:actions.LOAD_INSCRITO_SUCCESS,payload:{inscrito:aInscrito}});
}

function* downloadFile(data) {
  try{
    const url = yield call(rsf.storage.getDownloadURL, data.payload.caminho);
    
    yield call(handleDownload, url,data.payload.fileName);
  }catch(erro){
    console.log(erro)
  }
  
}

function* loadFromFirestore({ payload }) {
  try {
    const snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME);
    let data;
    snapshots.forEach(user => {
        data = {
          ...data,
          [user.id]: user.data()
        }
    });
    
    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error)
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* loadAnexos({ payload }) {
  try {
    const snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME+"/"+payload.key+"/arquivos/");
    let data;
    let anexos={};
    snapshots.forEach(dt => {
        data = {
          ...data,
          [dt.id]: dt.data()
        }
    });
    anexos[payload.key]=data;
    yield put(actions.loadAnexosSuccess(anexos));
  } catch (error) {
    console.log(error)
    yield put(actions.loadAnexosError(error));
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName } = payload;
  const fdata = {...omit(data, ['file'],['img'])}
  try {
    // yield call(uploadFile,'private/curso/'+data.email,data.file)
    let id_curso;
    id_curso = data.key;
    switch (actionName) {
      case 'delete':
        yield call(
          rsf.firestore.deleteDocument,
          `${COLLECTION_NAME}/${data.key}`);
        break;
      case 'update':
        yield call(
          rsf.firestore.setDocument,
          `${COLLECTION_NAME}/${data.key}`,
          {
            ...omit(fdata, ['key']),
          }
        );
        if(data.file){
          yield call(uploadFiles,'cqc/cursosonline/'+data.key+"/",data.file,data.key, payload.email)
          yield put({ type: actions.LOAD_ANEXOS, payload:{email:payload.email, key:data.key}});
        }
        break;
      default:
        const key = yield call(rsf.firestore.addDocument, `${COLLECTION_NAME}`, fdata);
        id_curso = key.id;
        if(data.file){
          yield call(uploadFiles,'cqc/cursosonline/'+key.id+"/",data.file,key.id, payload.email)
          yield put({ type: actions.LOAD_ANEXOS, payload:{email:payload.email, key:key.id}});
        }
        break;
    }
    if(data.img){
      console.log()
      yield call(uploadFileImg,'public/capa_cursosonline/'+id_curso, data.img,id_curso);
    }
  
    yield put({ type: actions.LOAD_FROM_FIRESTORE, payload:{email:payload.email}});
    yield call(notifications.success,{
      message: 'Curso on-line salvo com sucesso!',
      description:
        '',
    });
    
  } catch (error) {
    console.log(error);
    yield call(notifications.error,{
      message: 'Erro',
      description:
          'Houve um erro ao tentar salvar a informação!',
    });
    yield put(actions.saveIntoFireStoreError(error));
  }
}

function* uploadFileImg(path, file,key) {
  const task = rsf.storage.uploadFile(path, file);
  const channel = eventChannel(emit => task.on('state_changed', emit));
  yield task
  yield call(updatePathUploadImg,path,key)
}

function* updatePathUploadImg(path,key){
  let url = yield call(getUrl,path)
  yield call(
    rsf.firestore.setDocument,
    `cursos_on_line/${key}`,
    {
      "caminho":path,
      "urlFoto":url.replace(/&token(.*)/, '')
    },{merge: true}
  );
}

function* getUrl(caminho) {
  try{
    const url = yield call(rsf.storage.getDownloadURL, caminho);
    return url;
  }catch(erro){
    console.log(erro)
    return '';
  }
  
}

function* uploadFiles(path, file,key,email) {
  if(file.length >0){
    yield all(file.map(f=>{
      return call(uploadFile,path+f.name,f,key,f.name,email)
    }))
  }
}

function* uploadFile(path, file,key,fileName,email) {
  const task = rsf.storage.uploadFile(path, file);
  const channel = eventChannel(emit => task.on('state_changed', emit));
  yield task
  yield call(updatePathUpload,path,key,fileName,email)
}

function* updatePathUpload(path,key,fileName,email){
  yield call(
    rsf.firestore.addDocument,
    `${COLLECTION_NAME}/${email}/cursosonline/${key}/arquivos`,
    {
      "caminho":path,
      "fileName":fileName
    }
  );
}

function* handleDownload(url, filename){
  axios.get(url, {
    responseType: 'blob',
  })
  .then((res) => {
    fileDownload(res.data, filename)
  })
}

function* deleteAnexo({payload}) {
  try{
    if(payload.path && payload.keyAnexo){
      yield call(rsf.firestore.deleteDocument, COLLECTION_NAME+"/"+payload.path+payload.keyAnexo);
      let s = payload.path.split("/");
      yield put({type: actions.LOAD_ANEXOS, payload:{email:s[0],key:s[2]}});
      // yield call(loadAnexos,{payload:{email:s[0],key:s[2]}})
    }
  }catch(erro){
    console.log(erro)
  }
  
  
}

function* publishCourse({payload}) {
  try{
    const { email, key, data } = payload;
    let newData;
    if(key){
      newData = {...data, email, key}
      yield call(rsf.firestore.setDocument, `${COLLECTION_NAME}/${key}`, {published:true},{merge:true});
      yield put({type: actions.LOAD_FROM_FIRESTORE, payload:{email}});
    }
  }catch(erro){
    console.log(erro)
  }
}

function* unpublishCourse({payload}) {
  try{
    const { email, key, data } = payload;
    let newData;
    if(key){
      newData = {...data, email, key}
      yield call(rsf.firestore.setDocument, `${COLLECTION_NAME}/${key}`, {published:false},{merge:true});
      yield put({type: actions.LOAD_FROM_FIRESTORE, payload:{email}});
    }
  }catch(erro){
    console.log(erro)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD, load),
    takeEvery(actions.LOAD_CURSOS, loadCursos),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
    takeEvery(actions.LOAD_MODULO, loadModulo),
    takeEvery(actions.DOWNLOAD, downloadFile),
    takeEvery(actions.LOAD_ANEXOS, loadAnexos),
    takeEvery(actions.INSCREVER, inscrever),
    takeEvery(actions.FEEDBACK, feedback),
    takeEvery(actions.PERGUNTAR, perguntar),
    takeEvery(actions.WAS_LOAD, wasLoad),
    takeEvery(actions.LOAD_INSCRITO, loadInscrito),
    takeEvery(actions.PLAY_FINISHED, playFinished),
    takeEvery(actions.PLAY_INIT, playInit),
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.DELETE_ANEXO, deleteAnexo),
    takeEvery(actions.PUBLISH_COURSE, publishCourse),
    takeEvery(actions.UNPUBLISH_COURSE, unpublishCourse),
  ]);
}
