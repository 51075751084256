const DOCUMENT = 'CART_';

const actions = {
  LOAD: DOCUMENT + 'LOAD',
  LOAD_SUCCESS: DOCUMENT + 'LOAD_SUCCESS',
  LOAD_ERROR: DOCUMENT + 'LOAD_ERROR',

  ADD: DOCUMENT + 'ADD',
  ADD_SUCCESS: DOCUMENT + 'ADD_SUCCESS',
  ADD_ERROR: DOCUMENT + 'ADD_ERROR',

  REMOVE: DOCUMENT + 'REMOVE',
  REMOVE_ERROR: DOCUMENT + 'REMOVE_ERROR',

  UPDATE: DOCUMENT + 'UPDATE',

  add: (product,idProduct,redirect='') => {
    return { 
      type: actions.ADD,
      payload:{product,idProduct, redirect}};
  },
};
export default actions;
