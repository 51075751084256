import { all, takeEvery, put, fork, call, take } from 'redux-saga/effects';
// import { push } from 'react-router-redux';
import { getToken, clearToken } from '../../helpers/utility';
import actions from './actions';
import Usuario from '../../model/Usuario';
import FirebaseHelper from '../../helpers/firebase';
import actionsCredencial from '../credencial/actions';
import actionsHotmart from '../hotmart/actions';
import omit from 'lodash/omit';
import firebase from 'firebase';

const {rsf} = FirebaseHelper;

const COLLECTION_NAME = 'usuarios'; 

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload, save_password }) {
    //const { token } = payload;
    if (payload) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: payload,
        save_password
      });
    } else {
      yield put({ type: actions.LOGIN_ERROR });

    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload,save_password=false) {
    if(save_password){
      yield localStorage.setItem('id_token', payload.ra);
    }
    
    // yield put(actionsCredencial.loadCredencial(payload));
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    try {
      const data = yield call(rsf.auth.signOut);
      //Resetar aquj as principais informações
      yield put({type: actionsCredencial.RESET})



    }
    catch(error) {
      console.log(error)
    }
    
  });
}

export function* getUser() {
  yield takeEvery(actions.GET_USER, function*(){
    Usuario.getUsers(us=>{
     
    });
    
  });
}

export function* checkAuthorization({payload}) {
  const {page} = payload
  try {
      // events on this channel fire when the user logs in or logs out
      const channel = yield call(rsf.auth.channel)
      while (true) {
        const { user } = yield take(channel)
        if (user){
          //Lança o atual acesso
          yield call(rsf.firestore.setDocument, 'log/'+user.email,{
            currentAccess: firebase.firestore.FieldValue.serverTimestamp()
          });
          yield put({type: actions.LOGIN_SUCCESS,
            payload: {...user,email:user.email, uid:user.uid,page}
           })
          yield put ({type:'CREDENCIAL_LOAD',uid:user.uid, email:user.email })
        }else{
          yield put({type:'NO_LOGGED'});
        }
      }
  }catch (error) {
    console.log(error);
  }
}

export function* emailVerificationSaga(actionCodeSettings) {
  try {
    yield call(rsf.auth.sendEmailVerification);
    console.log('e-mail enviado com sucesso!')
  }
  catch(error) {
    console.log('erro ao tentar enviar e-mail',error)
  }
}

export function* emailReset(email, actionCodeSettings) {
  try {
    yield call(rsf.auth.sendPasswordResetEmail, email.email);
    yield put({type:actions.EMAIL_RESET_OK})
  }
  catch(error) {
    yield put({type:actions.EMAIL_RESET_ER})
    console.log(error)
    
  }
}

export function* changeLogin({email}) {
  try {
    if(email!=''){
      yield put({type:actions.CHANGE_LOGIN_SUCCESS, payload:{email,page:'/'}})
      yield put({type:actionsCredencial.LOAD,email})
    }
  }
  catch(error) {
    console.log(error)
    
  }
}

function* createUser(payload) {
  try {
    const user = yield call(rsf.auth.createUserWithEmailAndPassword, payload.email, payload.password);
   
    yield put({type:'CREATE_USER_SUCCESS',name:payload.name, email:payload.email, user});
    yield updateProfileSaga(payload.name,payload,user.user);
    yield put(loginSuccess(user));
  }
  catch(error) {
    yield put({type:actions.CREATE_USER_ERROR,payload:error});
  }
}

function* updateProfileSaga(name,payload,user) {
  try {
   
      console.log(user)
      yield call( rsf.firestore.setDocument,
      `${COLLECTION_NAME}/${user.uid}`,{
        ...omit(payload, ['password']),
      },
      {merge:true});

      yield call(rsf.auth.updateProfile, {
        displayName: name
      });
    
  }
  catch(error) {
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHECK_AUTHORIZATION,checkAuthorization),
    takeEvery(actions.EMAIL_RESET,emailReset),
    takeEvery(actions.CHANGE_LOGIN,changeLogin),
    takeEvery(actions.CREATE_USER,createUser),
    takeEvery(actions.EMAIL_VERIFICATION_SAGA,emailVerificationSaga),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(getUser),
  ]);
}
