const DOCUMENT = 'CURSOS_';

const actions = {
  LOAD: DOCUMENT + 'LOAD',
  LOAD_SUCCESS: DOCUMENT + 'LOAD_SUCCESS',
  LOAD_ERROR: DOCUMENT + 'LOAD_ERROR',

  LOAD_MODULO: DOCUMENT + 'LOAD_MODULO',
  LOAD_MODULO_SUCCESS: DOCUMENT + 'LOAD_MODULO_SUCCESS',
  LOAD_MODULO_ERROR: DOCUMENT + 'LOAD_MODULO_ERROR',

  LOAD_INSCRITO: DOCUMENT + 'LOAD_INSCRITO',
  LOAD_INSCRITO_SUCCESS:DOCUMENT + 'LOAD_INSCRITO_SUCCESS',

  WAS_LOAD:DOCUMENT + 'WAS_LOAD',

  INSCREVER: DOCUMENT + 'INSCREVER',
  INSCREVER_SUCCESS: DOCUMENT + 'INSCREVER_SUCCESS',

  FEEDBACK: DOCUMENT + 'FEEDBACK',
  FEEDBACK_SUCCESS: DOCUMENT + 'FEEDBACK_SUCCESS',
  FEEDBACK_ERROR: DOCUMENT + 'FEEDBACK_ERROR',

  PERGUNTAR: DOCUMENT + 'PERGUNTAR',
  PERGUNTAR_SUCCESS: DOCUMENT + 'PERGUNTA_SUCCESS',
  PERGUNTAR_ERROR: DOCUMENT + 'PERGUNTA_ERROR',

  DOWNLOAD: DOCUMENT + 'DOWNLOAD',

  LOAD_ANEXOS: DOCUMENT + 'LOAD_ANEXOS',
  LOAD_ANEXOS_SUCCESS: DOCUMENT + 'LOAD_ANEXOS_SUCCESS',
  LOAD_ANEXOS_ERROR: DOCUMENT + 'LOAD_ANEXOS_ERROR',

  PLAY_FINISHED: DOCUMENT + 'PLAY_FINISHED',
  PLAY_FINISHED_SUCCESS: DOCUMENT + 'PLAY_FINISHED_SUCCESS',

  PLAY_INIT: DOCUMENT + 'PLAY_INIT',
  PLAY_INIT_SUCCESS: DOCUMENT + 'PLAY_INIT_SUCCESS',

  LOAD_FINISHED: DOCUMENT + 'LOAD_FINISHED',
  LOAD_FINISHED_SUCCESS: DOCUMENT + 'LOAD_FINISHED_SUCCESS',
  LOAD_FINISHED_ERROR: DOCUMENT + 'LOAD_FINISHED_ERROR',

  LOAD_INIT: DOCUMENT + 'LOAD_INIT',
  LOAD_INIT_SUCCESS: DOCUMENT + 'LOAD_INIT_SUCCESS',
  LOAD_INIT_ERROR: DOCUMENT + 'LOAD_INIT_ERROR',

  FILTER: DOCUMENT + 'FILTER',
  FILTER_NOME: DOCUMENT + 'FILTER_NOME',
  FILTER_RESET: DOCUMENT + 'FILTER_RESET',


  load: (idCurso,emailProfessor,emailAluno) => {
    return { type: actions.LOAD,
          payload: {idCurso, emailProfessor, emailAluno}};
  },
  filter: (campo,valor) => {
    return { 
          type: actions.FILTER,
          payload: {campo, valor}};
  },
  filterNome: (valor) => {
    return { 
          type: actions.FILTER_NOME,
          payload: {valor}};
  },
  filterReset: () => {
    return { 
          type: actions.FILTER_RESET
    }
  },
  loadModulo: (idCurso,emailProfessor) => {
    return { type: actions.LOAD_MODULO,
          payload: {idCurso, emailProfessor}};
  },

  loadInscrito: (emailAluno) => {
    return { type: actions.LOAD_INSCRITO,
          payload: {emailAluno}};
  },

  inscrever: (idCurso,emailAluno) => {
    return { type: actions.INSCREVER,
          payload: {idCurso, emailAluno}};
  },

  playFinished: (emailProfessor,emailAluno, idCurso, idModulo='',idAula='',mesAno='') => {
    return { type: actions.PLAY_FINISHED,
          payload: {
            emailProfessor:emailProfessor,
            idCurso:idCurso,
            idModulo:idModulo,
            idAula:idAula,
            emailAluno:emailAluno,
          }
        };
  },

  playInit: (emailProfessor, emailAluno, idCurso, idModulo='',idAula='', mesAno='') => {
    return { type: actions.PLAY_INIT,
          payload: {
            emailProfessor:emailProfessor,
            idCurso:idCurso,
            idModulo:idModulo,
            idAula:idAula,
            emailAluno:emailAluno,
            mesAno:mesAno
          }
        };
  },

  perguntar: (emailProfessor, emailAluno, pergunta, idCurso, idModulo='',idAula='',idSend) => {
    return { type: actions.PERGUNTAR,
          payload: {
            emailProfessor:emailProfessor,
            idCurso:idCurso,
            pergunta:pergunta, 
            idModulo:idModulo,
            idAula:idAula,
            emailAluno:emailAluno,
            idSend:idSend,
            respondido:false
          }
        };
  },

  feedback: (emailProfessor, emailAluno, rate, comentario, idCurso, idModulo='',idAula='', idSend) => {
    return { type: actions.FEEDBACK,
          payload: {
            emailProfessor:emailProfessor,
            idCurso:idCurso,
            rate:rate, 
            comentario:comentario,
            idModulo:idModulo,
            idAula:idAula,
            emailAluno:emailAluno,
            idSend:idSend,

          }
        };
  },

  loadSuccess: (data, inscrito, aLast, aFinished) => ({
    type: actions.LOAD_SUCCESS,
    payload: { data, inscrito, aLast, aFinished },
  }),

  loadModuloSuccess: (data) => ({
    type: actions.LOAD_MODULO_SUCCESS,
    payload: { data},
  }),

  loadInscritoSuccess: (inscrito) => ({
    type: actions.LOAD_SUCCESS,
    payload: { inscrito },
  }),

  loadError: error => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),

  loadAnexos: (emailProfessor, idCurso) => {
    return { type: actions.LOAD_ANEXOS,
          payload: {emailProfessor, idCurso}};
  },

  loadAnexosSuccess: data => ({
    type: actions.LOAD_ANEXOS_SUCCESS,
    payload: { data },
  }),

  loadAnexosError: error => ({
    type: actions.LOAD_ANEXOS_ERROR,
    payload: { error },
  }),

  downloadFile: (data, fileName) => ({
    type: actions.DOWNLOAD,
    payload: { 
      "caminho": data,
      "fileName":fileName 
    },
  }),
};
export default actions;
