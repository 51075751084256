const DOCUMENT = 'CONTACT_';

const actions = {
  SAVE_CONTACT: DOCUMENT + 'SAVE_CONTACT',
  SAVE_CONTACT_ERROR: DOCUMENT + 'SAVE_CONTACT_ERRO',
  SAVE_CONTACT_UPDATE: DOCUMENT + 'SAVE_CONTACT_UPDATE',
  SAVE_CONTACT_SUCCESS: DOCUMENT + 'SAVE_CONTACT_SUCCESS',

  SAVE_NEWSLETTER: DOCUMENT + 'SAVE_NEWSLETTER',
  SAVE_NEWSLETTER_ERROR: DOCUMENT + 'SAVE_NEWSLETTER_ERRO',
  SAVE_NEWSLETTER_UPDATE: DOCUMENT + 'SAVE_NEWSLETTER_UPDATE',
  SAVE_NEWSLETTER_SUCCESS: DOCUMENT + 'SAVE_NEWSLETTER_SUCCESS',


  saveContact: (data, actionName = 'insert') => ({
    type: actions.SAVE_CONTACT,
    payload: { data, actionName },
  }),

  saveNewsletter: (data, actionName = 'insert') => ({
    type: actions.SAVE_NEWSLETTER,
    payload: { data, actionName },
  }),


  updateContact: data => ({
    type: actions.SAVE_CONTACT_UPDATE,
    payload: { data },
  }),

  saveContactError: error => ({
    type: actions.SAVE_CONTACT_ERROR,
    payload: { error },
  }),
  saveNewslettererror: error => ({
    type: actions.SAVE_NEWSLETTER_ERROR,
    payload: { error },
  }),
};
export default actions;

