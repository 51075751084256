const DOCUMENT = 'ECOMMERCE_';

const actions = {
  LOAD: DOCUMENT + 'LOAD',
  LOAD_SUCCESS: DOCUMENT + 'LOAD_SUCCESS',
  LOAD_ERROR: DOCUMENT + 'LOAD_ERROR',

  ADD: DOCUMENT + 'ADD',
  ADD_ERROR: DOCUMENT + 'ADD_ERROR',

  REMOVE: DOCUMENT + 'REMOVE',
  REMOVE_ERROR: DOCUMENT + 'REMOVE_ERROR',

  UPDATE: DOCUMENT + 'UPDATE',

  loadFromFireStore: () => {
    return { type: actions.LOAD_FROM_FIRESTORE};
  },

  loadFromFireStoreSuccess: data => ({
    type: actions.LOAD_FROM_FIRESTORE_SUCCESS,
    payload: { data },
  }),

  loadFromFireStoreError: error => ({
    type: actions.LOAD_FROM_FIRESTORE_ERROR,
    payload: { error },
  }),

  saveIntoFireStore: (data, actionName = 'insert') => ({
    type: actions.SAVE_INTO_FIRESTORE,
    payload: { data, actionName },
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_FIRESTORE_HANDLE_MODAL,
    payload: { data },
  }),

  update: data => ({
    type: actions.FIRESTORE_UPDATE,
    payload: { data },
  }),

  saveIntoFireStoreError: error => ({
    type: actions.SAVE_INTO_FIRESTORE_ERROR,
    payload: { error },
  }),

  resetFireStoreDocuments: () => ({
    type: actions.RESET_FIRESTORE_DOCUMENTS,
  }),

  resetFireStoreDocumentsError: error => ({
    type: actions.RESET_FIRESTORE_DOCUMENTS_ERROR,
    payload: { error },
  }),
};
export default actions;
