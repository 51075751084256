import actions from './actions';
const initState = {
  isLoading: false,
  errorMessage: false,
  cart: {},
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fdatas: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Houve um problema no carregamento',
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        fdata: payload.data == null ? initState.fdata : payload.data,
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        fdata: payload.data,
      };
    default:
      return state;
  }
}
