import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../components/Header';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/instructor.js';
import { connect } from 'react-redux';

class Instructor extends Component {
    
    render() {
        const re = new RegExp("^((http(s?):\/\/))")

        const showIcon=(icons,type)=>{

            if(type=='facebook'){
                if(re.test(icons.facebook)) {
                    return(<li className="list-inline-item"><a target="_BLANK" href={process.env.PUBLIC_URL + icons.facebook}><i className="fab fa-facebook-f"></i></a></li>)
                }
            }
           
            if(type=='linkedin'){
                if(re.test(icons.linkedin)){
                    return(<li className="list-inline-item"><a target="_BLANK" href={process.env.PUBLIC_URL + icons.linkedin}><i className="fab fa-linkedin"></i></a></li>)
                }
            }
            
            if(type=='instagram'){
                if(re.test(icons.instagram)){
                    return(<li className="list-inline-item"><a target="_BLANK" href={process.env.PUBLIC_URL + icons.instagram}><i className="fab fa-instagram"></i></a></li>)
                }
            }

            if(type=='youtube'){
                if(re.test(icons.youtube)){
                    return(<li className="list-inline-item"><a target="_BLANK" href={process.env.PUBLIC_URL + icons.youtube}><i className="fab fa-youtube"></i></a></li>)
                }   
            }
        }

        const showInstrutor=(dataInst)=>{
            try{
                if(Object.keys(dataInst.instrutores).length >0){
                    let instrutor;
                    instrutor = Object.keys(dataInst.instrutores).map((kdata, index) => {
                        return (
                            <Col lg="3" md="4" sm="6" key={"instru"+kdata}>
                            <div className="instructor-item">
                                <Link to={process.env.PUBLIC_URL + "/instructor-details"}><img src={dataInst.instrutores[kdata]['urlFoto']} alt="" className="img-fluid" /></Link>
                                <div className="img-content text-center">
                                    <h5>{dataInst.instrutores[kdata]['nome']}</h5>
                                    <p>Instrutor</p>
                                    <ul className="list-unstyled list-inline">
                                        {showIcon(dataInst.instrutores[kdata],'facebook')}
                                        {showIcon(dataInst.instrutores[kdata],'instagram')}
                                        {showIcon(dataInst.instrutores[kdata],'youtube')}
                                        {showIcon(dataInst.instrutores[kdata],'linkedin')}
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        )
                      })
                      return instrutor;
                }
            }catch(error){
            }
            
        }

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper instructor-page">

                    {/* Header 2 */}
                    <Header/>

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Instrutores" />

                    {/* Instructor Area */}
                    <section className="instructor-area">
                        <Container>
                            <Row>
                                { showInstrutor(this.props.Data)  }
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default connect(
    state => ({
        Data: state.PageHome,
    })  
  )(Instructor);