import { all, takeEvery, put, call} from 'redux-saga/effects';
import { eventChannel } from "redux-saga";
import actions from './actions';
import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';
import axios from 'axios'
import fileDownload from 'js-file-download'

const COLLECTION_NAME = 'cad_aluno'; // change your collection

function* loadFromFirestore({ payload }) {
  try {
    const snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME+"/"+payload.email+"/cadastro");
    let data={};
    if(snapshots){
      
      snapshots.forEach(user => {
          data = {
            ...data,
            [user.id]: user.data()
          }
      });
    }
    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error)
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName, email } = payload;
  const fdata = data
  try {
    
    switch (actionName) {
      case 'delete':
        yield call(
          rsf.firestore.deleteDocument,
          `${COLLECTION_NAME}/${email}/cadastro/${data.key}`);
        break;
      case 'update':
        yield call(
          rsf.firestore.setDocument,
          `${COLLECTION_NAME}/${email}/cadastro/${data.key}`,
          {
            ...omit(fdata, ['key']),
          }
        );
        break;
      default:
        const key = yield  call(rsf.firestore.addDocument, `${COLLECTION_NAME}/${email}/cadastro`, fdata);
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE, payload:{email}});
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoFireStoreError(error));
  }
}

function* updatePathUpload(path,key,fileName){
  yield call(
    rsf.firestore.setDocument,
    `${COLLECTION_NAME}/${key}`,
    {
      "caminho":path,
      "fileName":fileName
    },{merge: true}
  );
}

function* downloadFile(data) {
  try{
    const url = yield call(rsf.storage.getDownloadURL, data.payload.caminho);
    
    yield call(handleDownload, url,data.payload.fileName);
  }catch(erro){
    console.log(erro)
  }
  
}

function* handleDownload(url, filename){
  axios.get(url, {
    responseType: 'blob',
  })
  .then((res) => {
    fileDownload(res.data, filename)
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
    takeEvery(actions.DOWNLOAD, downloadFile),
    // takeEvery(actions.RESET_FIRESTORE_DOCUMENTS, resetFireStoreDocuments),
  ]);
}
