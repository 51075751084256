import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  assinaturas:{},
  produtos:{},
  assinante:false,
  loadAssinatura:false,
  loadProdutos:false
};

export default function reducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.CHECK_ASSINATURA_SUCCESS:
      return {
        ...state,
        assinaturas: payload.assinaturas
      };
    case actions.CHECK_PRODUTOS_SUCCESS:
      return {
        ...state,
        produtos: payload.produtos
      };  
    case actions.CONCLUIR_PEDIDO_SUCCESS:
      return {
        ...state,
        idPagamento:payload.id,
        linkPagamento:payload.shortUrl,
        step: {...state.step, [1]:true}
      };  
    case actions.CONCLUIR_PEDIDO:
      return {
        ...state,
        buttonDisabled:true,
        status:'init'
      }; 
    case actions.CONCLUIR_PEDIDO_ERROR:
      return {
        ...state,
        buttonDisabled:false
      }; 
      
    case actions.CONSULTA_PAGAMENTOS:
      return {
        ...state,
        status:'consultando'
      }; 

    case actions.CONSULTA_PAGAMENTOS_SUCCESS:
      return {
        ...state,
        status:'consultado'
      };   

    case actions.CONSULTA_PAGAMENTOS_ERROR:
      return {
        ...state,
        status:'init'
      };
      
    case actions.RESET:
      return {
        ...initState,
      };  

    default:
      return state;
  }
}
