import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import actionsCredencial from '../credencial/actions';
import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';
import {
  convertCollectionsSnapshotToMap,
  deleteDocuments,
  addCollectionAndDocuments,
} from '@iso/lib/firebase/firebase.util';


/**
 * DOC: https://redux-saga-firebase.js.org/reference/dev/firestore
 */

const COLLECTION_NAME = 'cad_gestor'; // change your collection
const ORDER_BY = 'id';
const ORDER = 'nome';

function* loadFromFirestore() {
  try {
    // const collectionRef = db
    //   .collection(COLLECTION_NAME)
    //   .where('deleted_at', '==', null)
    //   .orderBy(ORDER_BY, ORDER);

    // const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    const snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME);
    let data;
    snapshots.forEach(user => {
        data = {
          ...data,
          [user.id]: user.data()
        }
    });
    // let data = yield call(convertCollectionsSnapshotToMap, snapshots);
    
    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error)
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case 'delete':
        yield call(
          rsf.firestore.deleteDocument,
          `${COLLECTION_NAME}/${data.key}`);
          yield put(actionsCredencial.saveIntoFireStore({email:data.email, gestor:false},'delete'));
        break;
      case 'update':
        yield call(
          rsf.firestore.setDocument,
          `${COLLECTION_NAME}/${data.key}`,
          {
            ...omit(data, ['key']),
          }
        );
        break;
      default:
        yield call(rsf.firestore.addDocument, COLLECTION_NAME, data);
        yield put(actionsCredencial.saveIntoFireStore({email:data.email,gestor:true},'insert'));
        yield put({ type: actions.LOAD_FROM_FIRESTORE });
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE });
    
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoFireStoreError(error));
  }
}

// function* resetFireStoreDocuments() {
//   try {
//     yield call(deleteDocuments, COLLECTION_NAME);
//     yield call(addCollectionAndDocuments, COLLECTION_NAME, fakeDataList);
//     yield put({ type: actions.LOAD_FROM_FIRESTORE });
//   } catch (error) {
//     console.log(error);
//   }
// }

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
    // takeEvery(actions.RESET_FIRESTORE_DOCUMENTS, resetFireStoreDocuments),
  ]);
}
