'use strict'

module.exports = class Object{
    
    getValue(obj,campo, valueDefault=''){
        
        if(typeof(obj)=='object'){
            if(obj.hasOwnProperty(campo)){
                
                if(obj[campo]){
                    return obj[campo];
                }else{
                    return valueDefault;
                }
                
            }else{
                return valueDefault;
            }
        }else{
            return valueDefault;
        }
        
       
    }
		

    

}