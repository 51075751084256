import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import omit from 'lodash/omit';
import { rsf, db} from '@iso/lib/firebase/firebase';
import notifications from '@iso/components/Feedback/Notification';
import axios from 'axios'
import firebase from 'firebase';

const COLLECTION_NAME = 'pedidos';

function* concluirPedido({ payload }) {
  try {
    let email = payload.email;
    let price = payload.total;
    let description = 'AssinaturaCqc';
    let SoftDescriptor = 'AssinaturaCqc';
    let Sku = 'assinatura';
    let name = 'Assinatura';
    let indicacao = payload.indicacao;
    let produtos = payload.produtosArray;

    const dt = yield call(axios.post,process.env.REACT_APP_WEBSERVICE+"hotmart/concluirPedido",{
      email:payload.email,
      price,
      description,
      SoftDescriptor,
      name,
      Sku,
      produtos,
    })
    let data = dt.data;

    yield call(rsf.firestore.setDocument, COLLECTION_NAME+"/"+payload.email+"/pedido/"+data.id, {
      ...data,
      indicacao,
      status:'aberto',
      data:firebase.firestore.FieldValue.serverTimestamp(),
      produtos
    }, { merge: true });

    yield call(rsf.firestore.setDocument, COLLECTION_NAME+"_aprocessar/"+data.id, {
      ...data,
      indicacao,
      status:'aberto',
      data:firebase.firestore.FieldValue.serverTimestamp(),
      produtos
    }, { merge: true });
    yield put({ type: actions.CONCLUIR_PEDIDO_SUCCESS, payload:{...data}});
  } catch (error) {
    yield put({ type: actions.CONCLUIR_PEDIDO_ERROR, payload:{error}});
    console.log(error);
  }
}

function* pedidoAberto({payload}) {
  try {
    //Busca os pedidos em aberto
    // const collectionRef = db
    //   .collection(COLLECTION_NAME+"/"+payload.email+"/cursos/"+payload.idCurso+"/modulos")
    //   .orderBy(ORDER_BY, ORDER)

    //   const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    //   let data;
    //   snapshots.forEach(user => {
    //       data = {
    //         ...data,
    //         [user.id]: user.data()
    //       }
    //   });
    //   let curso={};
    //   if(data){
    //     curso[payload.idCurso]=data;  
    //   }
  } catch (error) {
    
  }
}

//Consulta um pagamento especifico e se for pago lança o pagamento
/**
 * 
   "$id": "1",
   "productId": "9487e3a9-f204-4188-96c5-a5a3013b2517",
   "createdDate": "2019-07-11T10:35:04.947",
   "orders": [
       {
           "$id": "2",
           "orderNumber": "b74df3e3c1ac49ccb7ad89fde2d787f7",
           "createdDate": "2019-07-11T10:37:23.447",
           "payment": {
               "$id": "3",
               "price": 11500,
               "numberOfPayments": 6,
               "createdDate": "2019-07-11T10:37:23.447",
               "status": "Denied"
           },
           "links": [
               {
                   "$id": "4",
                   "method": "GET",
                   "rel": "self",
                   "href": "https://cieloecommerce.cielo.com.br/api/public/v2/orders/b74df3e3c1ac49ccb7ad89fde2d787f7"
               }
           ]
       }
   ]}
 */
function* consultaPagamento({payload}) {
  try {
    const dt = yield call(axios.post,process.env.REACT_APP_WEBSERVICE+"hotmart/consultaPagamento",{
      id:payload.id
    })
    let data = dt.data;

    if(data.orders){
      let pago=false;
      let cancelado=false;
      yield all (data.orders.map(doc=>{

        if(doc.payment.status=='Authorized'){
          pago = true;
        }
        if(pago && !cancelado){
          return call(lancaPagamento, {payload:{email:payload.email,produtos:payload.produtos,id:payload.id,pagamento:doc, indicacao:payload.indicacao}})
        }else if(cancelado){
          return call(lancaCancelamento, {payload:{email:payload.email,id:payload.id,pagamento:doc}})
        }
      })
      )
    }
    
  } catch (error) {
    console.log(error)
  }
  
}

function* lancaPagamento({payload}){
  try {
    yield call(lancaAssinatura, {payload})
       
  } catch (error) {
    console.log(error)
  }
}


//Faz o lançamento da assinatura,
//Colocar para verificar o tipo do produto se é assinatura
function* lancaAssinatura({payload}){
  try {
    const email = payload.email;
    let dt1, dt2, assinatura, validade
    assinatura=false
    let diasAcesso=0;

    //Retorna sessão atual de último acesso
    const snapshot = yield call(rsf.firestore.getDocument, 'log/'+email);
    const log = snapshot.data();
    dt1 = log.currentAccess.toDate()

    const collectionRef = db
        .collection("assinaturas/")
        .where("email","==",email)

      const snapshotsAssinatura = yield call(rsf.firestore.getCollection, collectionRef);
      
      snapshotsAssinatura.forEach(dt => {
        dt2 = new Date(dt.data().validade)
        if(!assinatura){
          if(dt1.getTime()<=dt2.getTime()){
            assinatura=true
            diasAcesso+=Math.ceil((dt2.getTime()-dt1.getTime())/(1000 * 60 * 60 * 24))
          }
        }
    });


    if(Object.keys(payload.produtos).length>0){
      yield all(Object.keys(payload.produtos).map((kdata, index) => {
          if(kdata=='assinaturaAnual'){
            validade = dt1.setDate(parseInt(dt1.getDate())+parseInt(diasAcesso)+parseInt(payload.produtos[kdata].dias_acessos)).valueOf();
            return call(rsf.firestore.setDocument, 'assinaturas/'+payload.id, {
              email: email,
              validade: validade,
              idLink: payload.id,
              orderNumber: payload.pagamento.orderNumber,
              price: payload.pagamento.payment.price,
              numberOfPayments: payload.pagamento.payment.numberOfPayments,
              createdDate: payload.pagamento.createdDate,
              status: payload.pagamento.payment.status,
              assinatura:'ativa'
            });
          }
        })
      ) 
    }

    yield call(rsf.firestore.setDocument, 'pedidos/'+payload.email+"/pedido/"+payload.id, {
        status:'concluido'
    },{merge:true});

    yield call(rsf.firestore.deleteDocument, COLLECTION_NAME+"_aprocessar/"+payload.id);

    yield put({ type: 'CREDENCIAL_LOAD_ASSINATURA', payload:{email}});

    yield call(lancaOrderPagamento,{payload: {email, orderNumber:payload.pagamento.orderNumber, indicacao:payload.indicacao}})
  } catch (error) {
    console.log(error)
  }
}


//Colocar aqui a indicação
function* lancaOrderPagamento({payload}){
  const dt = yield call(axios.post,process.env.REACT_APP_WEBSERVICE+"hotmart/consultaOrderPagamento",{
    id:payload.orderNumber,
  })
  let data = dt.data;


    console.log({indicacao:payload.indicacao,
      email:payload.email})
  yield call(rsf.firestore.setDocument, COLLECTION_NAME+"_faturar/"+payload.orderNumber, {
    ...data,
    // indicacao:payload.indicacao,
    email:payload.email,
    status:'aberto',
    data:firebase.firestore.FieldValue.serverTimestamp(),
  }, { merge: true });
}

//Colocar código para cancelamento
function* lancaCancelamento({payload}){
  try {


  } catch (error) {
    console.log(error)
  }
}



//Consulta vários pagamentos aberto pelo cliente... 
//Colocar parametro para excluir pedidos em aberto e desativar

//Desativado para o caso do HOTMART que JÁ FAZ A CONSULTA
function* consultaPagamentos({payload}) {
  try {
    // const collectionRef = db
    //     .collection(COLLECTION_NAME+"/"+payload.email+"/pedido/")
    //     .where("status","==",'aberto')

    //     const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    //     let dataFb;
    //     if(snapshots){
    //       yield all( 
    //         snapshots.docs.map(dt => {
    //           dataFb = dt.data()
    //          return call(consultaPagamento, {payload:{email:payload.email,id:dt.id,produtos:dataFb.produtos,...payload}})
    //       }))
    //     }
       
  } catch (error) {
    console.log(error)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.PEDIDO_ABERTO, pedidoAberto),
    takeEvery(actions.CONCLUIR_PEDIDO, concluirPedido),
    takeEvery(actions.CONSULTA_PAGAMENTO, consultaPagamento),
    takeEvery(actions.CONSULTA_PAGAMENTOS, consultaPagamentos),
  ]);
}
