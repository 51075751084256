import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';

function* loadFromFirestore() {
  try {
    let data=[];
    data['perguntas_respostas']=[];
    data['cursos']=[];
    data['cooperquintas']=[];
    data['eventos']=[];
    data['instrutores']=[];
    data['produtos']=[];
    data['categorias']=[];
    let snapshots = yield call(rsf.firestore.getCollection, 'perguntas_respostas');
      snapshots.forEach(doc => {
        //Deu certo porque funciona a função MAP
          data.perguntas_respostas.push(doc.data());
      });  
      snapshots = yield call(rsf.firestore.getCollection, 'cursos');
      snapshots.forEach(doc => {
        //Fazer testes, pois não funciona com a função MAP
          data['cursos'] = {
            ...data['cursos'],
            [doc.id]: doc.data()
          }
      }); 

      snapshots = yield call(rsf.firestore.getCollection, 'cad_cooperquintas');
      snapshots.forEach(doc => {
        //Fazer testes, pois não funciona com a função MAP
          data['cooperquintas'] = {
            ...data['cooperquintas'],
            [doc.id]: {...doc.data(),key:doc.id}
          }
      }); 

      snapshots = yield call(rsf.firestore.getCollection, 'cad_instrutor_perfil');

      snapshots.forEach(doc => {
          data['instrutores'] = {
            ...data['instrutores'],
            [doc.id]: doc.data()
          }
          // if(data['instrutores'][doc.id]['caminho']){
          //   urlPerfil = call(rsf.storage.getDownloadURL,data['instrutores'][doc.id]['caminho']);

          //   data['instrutores'][doc.id]['urlPerfim']= urlPerfil;
          //   console.log(data['instrutores'][doc.id])
          // }
          
      });  

      snapshots = yield call(rsf.firestore.getCollection, 'produtos');

      snapshots.forEach(doc => {
          data['produtos'] = {
            ...data['produtos'],
            [doc.id]: doc.data()
          }
      });  

      snapshots = yield call(rsf.firestore.getCollection, 'cad_categoria');

      snapshots.forEach(doc => {
          data['categorias'] = {
            ...data['categorias'],
            [doc.id]: doc.data()
          }
      });  

    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error)
    yield put(actions.loadFromFireStoreError(error));
  }
}
function* verifyLoadFromFirestore({payload}){
  const {wasLoading} = payload;
  if(!wasLoading){
    yield put(actions.loadFromFireStore());
  }
  
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.VERIFY_LOAD_FROM_FIRESTORE, verifyLoadFromFirestore),
    
  ]);
}
