import { all, takeEvery, put, call} from 'redux-saga/effects';
import { eventChannel } from "redux-saga";
import actions from './actions';
import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';
import axios from 'axios'
import fileDownload from 'js-file-download'
import {
  convertCollectionsSnapshotToMap,
  deleteDocuments,
  addCollectionAndDocuments,
} from '@iso/lib/firebase/firebase.util';


const COLLECTION_NAME = 'cad_cursos'; // change your collection

function* loadFromFirestore({ payload }) {
  try {
    const ORDER_BY = 'numero_modulo';
    const ORDER = 'asc';

    const collectionRef = db
      .collection(COLLECTION_NAME+"/"+payload.email+"/cursos/"+payload.key+"/modulos")
      .orderBy(ORDER_BY, ORDER)

      const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    // const snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME+"/"+payload.email+"/cursos/"+payload.key+"/modulos");
    let data;
    snapshots.forEach(user => {
        data = {
          ...data,
          [user.id]: {...user.data(), key:user.id}
        }
    });
    // let data = yield call(convertCollectionsSnapshotToMap, snapshots);
    
    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error)
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* loadAnexos({ payload }) {
  try {
    const snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME+"/"+payload.email+"/cursos/"+payload.key+"/modulos/"+payload.keyModulo+"/arquivos/");
    let data;
    let anexos={};
    snapshots.forEach(dt => {
        data = {
          ...data,
          [dt.id]: dt.data()
        }
    });
    anexos[payload.keyModulo]=data;
    yield put(actions.loadAnexosSuccess(anexos));
  } catch (error) {
    console.log(error)
    yield put(actions.loadAnexosError(error));
  }
}


function* storeIntoFirestore({payload}) {
  const { data, actionName, email, idCurso } = payload;
  const fdata = {...omit(data, ['file'])}
  try {
    // yield call(uploadFile,'private/curso/'+data.email,data.file)

    switch (actionName) {
      case 'delete':
        console.log(`${COLLECTION_NAME}/${email}/cursos/${idCurso}/modulos/${data.key}`);
        yield call(
          rsf.firestore.deleteDocument,
          `${COLLECTION_NAME}/${email}/cursos/${idCurso}/modulos/${data.key}`);
        break;
      case 'update':
        if(data.file){
          yield call(uploadFiles,'cqc/cursos/'+payload.email+'/'+idCurso+"/modulos/"+data.key+"/",data.file,idCurso, payload.email,data.key)
          yield put({ type: actions.LOAD_ANEXOS, payload:{email:payload.email, key:idCurso, keyModulo:data.key}});
        }
        yield call(
          rsf.firestore.setDocument,
          `${COLLECTION_NAME}/${email}/cursos/${idCurso}/modulos/${data.key}`,
          {
            ...omit(fdata, ['key']),
          }
        );
        
        break;
      default:
        const key = yield call(rsf.firestore.addDocument, `${COLLECTION_NAME}/${email}/cursos/${idCurso}/modulos`, fdata);
        if(data.file){
          yield call(uploadFiles,'cqc/cursos/'+payload.email+'/'+idCurso+"/modulos/"+key.id+"/",data.file, idCurso, payload.email, key.id)
          yield put({ type: actions.LOAD_ANEXOS, payload:{email:payload.email, key:idCurso, keyModulo:key.id}});
        }
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE, payload:{email, key:idCurso}});
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoFireStoreError(error));
  }
}

function* uploadFiles(path, file,key,email, keyModulo) {
  if(file.length >0){
    yield all(file.map(f=>{
      return call(uploadFile,path+f.name,f,key,f.name,email,keyModulo)
    }))
  }
}

function* uploadFile(path, file,key,fileName,email,keyModulo) {
  const task = rsf.storage.uploadFile(path, file);
  const channel = eventChannel(emit => task.on('state_changed', emit));
  yield task
  yield call(updatePathUpload,path,key,fileName,email, keyModulo)
}

function* updatePathUpload(path,key,fileName,email,keyModulo){
  yield call(
    rsf.firestore.addDocument,
    `${COLLECTION_NAME}/${email}/cursos/${key}/modulos/${keyModulo}/arquivos`,
    {
      "caminho":path,
      "fileName":fileName
    }
  );
}


function* downloadFile(data) {
  try{
    const url = yield call(rsf.storage.getDownloadURL, data.payload.caminho);
    
    yield call(handleDownload, url,data.payload.fileName);
  }catch(erro){
    console.log(erro)
  }
  
}

function* handleDownload(url, filename){
  axios.get(url, {
    responseType: 'blob',
  })
  .then((res) => {
    fileDownload(res.data, filename)
  })
}

function* deleteAnexo({payload}) {
  try{
    if(payload.path && payload.keyAnexo){
      yield call(rsf.firestore.deleteDocument, COLLECTION_NAME+"/"+payload.path+payload.keyAnexo);
      let s = payload.path.split("/");
      yield put({type: actions.LOAD_ANEXOS, payload:{email:s[0],key:s[2],keyModulo:s[4]}});
    }
  }catch(erro){
    console.log(erro)
  }
  
  
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
    takeEvery(actions.DOWNLOAD, downloadFile),
    takeEvery(actions.LOAD_ANEXOS, loadAnexos),
    takeEvery(actions.DELETE_ANEXO, deleteAnexo),
  ]);
}
