import { all, takeEvery, put, call} from 'redux-saga/effects';
import { eventChannel } from "redux-saga";
import actions from './actions';
import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';
import axios from 'axios'
import fileDownload from 'js-file-download'

const COLLECTION_NAME = 'cad_formularios'; // change your collection
const ORDER_BY = 'id';
const ORDER = 'nome';

function* loadFromFirestore({ payload }) {
  try {
    // const collectionRef = db
    //   .collection(COLLECTION_NAME)
    let collectionRef;
    let snapshots;

      if(payload.data=='instrutor'){
        collectionRef = db
            .collection(COLLECTION_NAME)
            .where('instrutor', '==', true);
        snapshots = yield call(rsf.firestore.getCollection, collectionRef);
      }else if(payload.data=='instituicao'){
        collectionRef = db
            .collection(COLLECTION_NAME)
            .where('instituicao', '==', true)
            snapshots = yield call(rsf.firestore.getCollection, collectionRef);

      }else if(payload.data=='aluno'){
        collectionRef = db
            .collection(COLLECTION_NAME)
            .where('aluno', '==', true)
            snapshots = yield call(rsf.firestore.getCollection, collectionRef);
      }
    let data;
    if(snapshots){
      snapshots.forEach(user => {
          data = {
            ...data,
            [user.id]: user.data()
          }
      });
    }
    
    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error)
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName } = payload;
  const fdata = {...omit(data, ['file'])}
  try {
    
    switch (actionName) {
      case 'delete':
        yield call(
          rsf.firestore.deleteDocument,
          `${COLLECTION_NAME}/${data.key}`);
        break;
      case 'update':
        yield call(
          rsf.firestore.setDocument,
          `${COLLECTION_NAME}/${data.key}`,
          {
            ...omit(fdata, ['key']),
          }
        );
        break;
      default:
        const key = yield  call(rsf.firestore.addDocument, COLLECTION_NAME, fdata);
        if(data.file.name){
          yield call(uploadFile,'cqc/formularios/'+key.id+"/"+data.file.name,data.file,key.id,data.file.name)
        }
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoFireStoreError(error));
  }
}

function* uploadFile(path, file,key,fileName) {
  const task = rsf.storage.uploadFile(path, file);
  const channel = eventChannel(emit => task.on('state_changed', emit));
  // yield takeEvery(channel, );
  // Wait for upload to complete
  yield task
  yield call(updatePathUpload,path,key,fileName)
  // Do something on complete
}

function* updatePathUpload(path,key,fileName){
  yield call(
    rsf.firestore.setDocument,
    `${COLLECTION_NAME}/${key}`,
    {
      "caminho":path,
      "fileName":fileName
    },{merge: true}
  );
}

function* downloadFile(data) {
  try{
    const url = yield call(rsf.storage.getDownloadURL, data.payload.caminho);
    
    yield call(handleDownload, url,data.payload.fileName);
  }catch(erro){
    console.log(erro)
  }
  
}

function* handleDownload(url, filename){
  axios.get(url, {
    responseType: 'blob',
  })
  .then((res) => {
    fileDownload(res.data, filename)
  })
}

// function* downloadFormulario(path){
//   // Create a reference to the file we want to download
//   var starsRef = storageRef.child('images/stars.jpg');

//   // Get the download URL
//   starsRef.getDownloadURL().then(function(url) {
//     // Insert url into an <img> tag to "download"
//   }).catch(function(error) {

//     // A full list of error codes is available at
//     // https://firebase.google.com/docs/storage/web/handle-errors
//     switch (error.code) {
//       case 'storage/object-not-found':
//         // File doesn't exist
//         break;

//       case 'storage/unauthorized':
//         // User doesn't have permission to access the object
//         break;

//       case 'storage/canceled':
//         // User canceled the upload
//         break;

//       ...

//       case 'storage/unknown':
//         // Unknown error occurred, inspect the server response
//         break;
//     }
//   });
// }

// function* resetFireStoreDocuments() {
//   try {
//     yield call(deleteDocuments, COLLECTION_NAME);
//     yield call(addCollectionAndDocuments, COLLECTION_NAME, fakeDataList);
//     yield put({ type: actions.LOAD_FROM_FIRESTORE });
//   } catch (error) {
//     console.log(error);
//   }
// }

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
    takeEvery(actions.DOWNLOAD, downloadFile),
    // takeEvery(actions.RESET_FIRESTORE_DOCUMENTS, resetFireStoreDocuments),
  ]);
}
