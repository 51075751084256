import { all, takeEvery, put, call} from 'redux-saga/effects';
import { eventChannel } from "redux-saga";
import actions from './actions';
import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';
import axios from 'axios'
import fileDownload from 'js-file-download'
import firebase from 'firebase'

const COLLECTION_NAME = 'cad_cursos';

function* load({ payload }) {
  try {
    const ORDER_BY = 'numero_modulo';
    const ORDER = 'asc';

    //Busca todos os módulos do curso
    const collectionRef = db
      .collection(COLLECTION_NAME+"/"+payload.emailProfessor+"/cursos/"+payload.idCurso+"/modulos")
      .orderBy(ORDER_BY, ORDER)

      const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
      let data;
      snapshots.forEach(user => {
          data = {
            ...data,
            [user.id]: {...user.data(),key: user.id},
            
          }
      });
      let curso={};
      if(data){
        curso[payload.idCurso]=data;  
      }

    //Verifica se o aluno está inscrito no curso
    const inscrito = yield call(rsf.firestore.getDocument, "curso_inscricao/inscricao"+"/"+payload.emailAluno+"/"+payload.idCurso);
    let dtInscrito = inscrito.data();
    let isInscrito = false;
    if(dtInscrito){
      if(dtInscrito.inscrito){
        isInscrito = true;
      }
    }
    let aInscrito={}
    aInscrito [payload.idCurso]=isInscrito;

    //Faz a consulta do último vídeo iniciado
    const last = yield call(rsf.firestore.getDocument, "aulas_last/"+payload.emailAluno+"/"+payload.idCurso+"/last");
    let dtLast= last.data();
    
    let aLast={}
    if(dtLast){
      aLast[payload.idCurso]={
        'aula':dtLast.aula,
        'dt':dtLast.dt
      }
    }

    //Busca os cursos terminados
    const finished = yield call(rsf.firestore.getCollection,"aulas_concluidas/"+payload.emailAluno+"/"+payload.idCurso);
      let dtFinished;
      finished.forEach(dtaF => {
        dtFinished = {
            ...dtFinished,
            [dtaF.id]: dtaF.id
          }
      });
      let aFinished={};
      if(dtFinished){
        aFinished[payload.idCurso]=dtFinished;  
      }

    yield put(actions.loadSuccess(curso,aInscrito, aLast,aFinished));

  } catch (error) {
    console.log(error)
    yield put(actions.loadError(error));
  }
}

//Busca as aulas assistidas



//Faz o load somente dos módulos
function* loadModulo({ payload }) {
  try {
    const ORDER_BY = 'numero_modulo';
    const ORDER = 'asc';

    //Busca todos os módulos do curso
    const collectionRef = db
      .collection(COLLECTION_NAME+"/"+payload.emailProfessor+"/cursos/"+payload.idCurso+"/modulos")
      .orderBy(ORDER_BY, ORDER)

      const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
      let data;
      snapshots.forEach(user => {
          data = {
            ...data,
            [user.id]: omit(user.data(),['link_video']),
            key:user.id
          }
      });
      let curso={};
      if(data){
        curso[payload.idCurso]=data;  
      }
    yield put(actions.loadModuloSuccess(curso));

  } catch (error) {
    console.log(error)
    yield put(actions.loadError(error));
  }
}

function* inscrever({payload}){
  try{
    yield call(rsf.firestore.setDocument, `curso_inscricao/inscricao/${payload.emailAluno}/${payload.idCurso}`, {
        dtInscricao: firebase.firestore.FieldValue.serverTimestamp(),
        inscrito:true
    },{merge:true});
    yield call(rsf.firestore.addDocument, `log_cursos/inscrito/${payload.idCurso}`, {
      dtInscrito: firebase.firestore.FieldValue.serverTimestamp(),
      email:payload.emailAluno,
      op:'inscrito'
  },{merge:true});
      yield put({type:actions.INSCREVER_SUCCESS,payload:{idCurso:payload.idCurso}});
  }catch(error){
    console.log(error)
  }
}

function* playFinished({payload}){
  try{
    let id;
    if(payload.idAula){
      id = payload.idAula
    }else{
      id = payload.idModulo
    }
    yield call(rsf.firestore.setDocument, `aulas_concluidas/${payload.emailAluno}/${payload.idCurso}/${id}`, {
        dt: firebase.firestore.FieldValue.serverTimestamp(),
        finished:true
    },{merge:true});
    yield put({type:actions.PLAY_FINISHED_SUCCESS,
      payload:{
        idModulo:id,
        "idCurso":payload.idCurso}
      });
  }catch(error){
    console.log(error)
  }
}

function* playInit({payload}){
  try{
    let id;
    if(payload.idAula){
      id = payload.idAula
    }else{
      id = payload.idModulo
    }
    yield call(rsf.firestore.setDocument, `aulas_iniciadas/${payload.emailAluno}/${payload.idCurso}/${id}`, {
      dt: firebase.firestore.FieldValue.serverTimestamp(),
      init:true,
  },{merge:true});
  yield call(rsf.firestore.setDocument, `acompanhamento_cursos/${payload.mesAno}/${payload.emailProfessor}/${payload.emailAluno}`, {
    q:firebase.firestore.FieldValue.increment(1),
    [id]:{
      dt: firebase.firestore.FieldValue.serverTimestamp(),
    }
  },{merge:true});
  yield call(rsf.firestore.setDocument, `aulas_last/${payload.emailAluno}/${payload.idCurso}/last`, {
    dt: firebase.firestore.FieldValue.serverTimestamp(),
    aula:id
},{merge:true});
    yield put({type:actions.PLAY_INIT_SUCCESS,payload:{id:id}});
  }catch(error){
    console.log(error)
  }
}

function* feedback({payload}){
  try{
    yield call(rsf.firestore.addDocument, `curso_feedback/feedback/${payload.idCurso}`, payload);
    yield put({type:actions.FEEDBACK_SUCCESS,
      payload:{
        idSend:payload.idSend}
      });
  }catch(error){
    console.log(error)
  }
}

function* perguntar({payload}){
  try{
    yield call(rsf.firestore.addDocument, `curso_perguntas/${payload.emailProfessor}/${payload.idCurso}`, payload);
    yield put({type:actions.PERGUNTAR_SUCCESS, 
      payload:{
        idSend:payload.idSend}
      });
  }catch(error){
    console.log(error)
  }
}

function* wasLoad({payload}){
  try{
    let was_load = payload.was_load;

    if(!was_load){
      yield put({type:actions.LOAD,payload:{idCurso:payload.idCurso}});
    }
  }catch(error){
    console.log(error)
  }
}

function* loadInscrito({payload}){
  const snapshots = yield call(rsf.firestore.getCollection, "curso_inscricao/inscricao"+"/"+payload.emailAluno);
  let aInscrito={}
  snapshots.forEach(user => {
      aInscrito = {
        ...aInscrito,
        [user.id]: true
      }
  });
  yield put({type:actions.LOAD_INSCRITO_SUCCESS,payload:{inscrito:aInscrito}});
}

function* loadAnexos({ payload }) {
  try {
    const snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME+"/"+payload.emailProfessor+"/cursos/"+payload.idCurso+"/arquivos/");
    let data;
    let anexos={};
    snapshots.forEach(dt => {
        data = {
          ...data,
          [dt.id]: dt.data()
        }
    });
    anexos[payload.idCurso]=data;
    yield put(actions.loadAnexosSuccess(anexos));
  } catch (error) {
    console.log(error)
    yield put(actions.loadAnexosError(error));
  }
}

function* downloadFile(data) {
  try{
    const url = yield call(rsf.storage.getDownloadURL, data.payload.caminho);
    
    yield call(handleDownload, url,data.payload.fileName);
  }catch(erro){
    console.log(erro)
  }
  
}

function* handleDownload(url, filename){
  axios.get(url, {
    responseType: 'blob',
  })
  .then((res) => {
    fileDownload(res.data, filename)
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD, load),
    takeEvery(actions.LOAD_MODULO, loadModulo),
    takeEvery(actions.DOWNLOAD, downloadFile),
    takeEvery(actions.LOAD_ANEXOS, loadAnexos),
    takeEvery(actions.INSCREVER, inscrever),
    takeEvery(actions.FEEDBACK, feedback),
    takeEvery(actions.PERGUNTAR, perguntar),
    takeEvery(actions.WAS_LOAD, wasLoad),
    takeEvery(actions.LOAD_INSCRITO, loadInscrito),
    takeEvery(actions.PLAY_FINISHED, playFinished),
    takeEvery(actions.PLAY_INIT, playInit),
  ]);
}
